import { Admin, CustomRoutes, Resource, Authenticated } from "react-admin";
import { BrowserRouter, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { CustomLayout } from "./layout/Layout";
import { FirebaseAuthProvider, FirebaseDataProvider } from "react-admin-firebase";
import CustomerList from "./components/Customers/List";
import CustomerEdit from "./components/Customers/Edit";
import CompanyProvider from "./context/CompanyContext";
import CustomerCreate from "./components/Customers/Create";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/auth";
import { theme } from "./layout/Theme";
import UserProvider from "./context/UserContext";
import UserList from "./components/Users/List";
import UserEdit from "./components/Users/Edit";
import UserCreate from "./components/Users/Create";
import ReportedReviews from "./components/ReportedReviews/ReportedReviews";
import CustomerReviews from "./components/CustomerReviews/Reviews";
import LoginPage from "./layout/Login/LoginPage";
import Competitors from "./components/Competitors/Competitors";
import MonitoredPortalsList from "./components/MonitoredPortals/List";

// dev
/*const config = {
    apiKey: "AIzaSyBj9iDWpaOmhNGMTmsuBc-E55rAensee3Q",
    authDomain: "startprotection-dev.firebaseapp.com",
    projectId: "startprotection-dev",
    storageBucket: "startprotection-dev.appspot.com",
    messagingSenderId: "554730626887",
    appId: "1:554730626887:web:599b50f5dd4fd45fa67662",
};*/

// beta
/*const config = {
    apiKey: "AIzaSyADlbJv0QF8SyDW6ep0wbPZjIf-c9rWmlw",
    authDomain: "startprotection-beta.firebaseapp.com",
    projectId: "startprotection-beta",
    storageBucket: "startprotection-beta.appspot.com",
    messagingSenderId: "1064106538653",
    appId: "1:1064106538653:web:745bfb539b021ab9e08674",
};*/

// live
const config = {
    apiKey: "AIzaSyBJtmvPnv1bA4d5-sKUsLDtRke5QHQx_u4",
    authDomain: "secdata-starprotection-2023.firebaseapp.com",
    projectId: "secdata-starprotection-2023",
    storageBucket: "secdata-starprotection-2023.appspot.com",
    messagingSenderId: "48460723693",
    appId: "1:48460723693:web:d11c274a96dd97f94fe3e4",
    measurementId: "G-0RBJMPJBER",
};

const firebaseApp = firebase.initializeApp(config);

export const firestore = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const firebaseFunctions = firebaseApp.functions("europe-west1");
//firebaseFunctions.useEmulator("localhost", 5001);

export const storage = firebaseApp.storage();

const options = {
    app: firebaseApp,
    //logging: true,
    lazyLoading: {
        enabled: true,
    },
};

const dataProvider = FirebaseDataProvider(config, options);
export const authProvider = FirebaseAuthProvider(config, options);

const App = () => (
    <BrowserRouter>
        <UserProvider>
            <CompanyProvider>
                <Admin
                    loginPage={LoginPage}
                    theme={theme}
                    dashboard={Dashboard}
                    layout={CustomLayout}
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                >
                    {(permissions) => (
                        <>
                            <Resource
                                name={"companies"}
                                list={permissions.role === "admin" ? CustomerList : null}
                                edit={CustomerEdit}
                                create={permissions.role === "admin" ? CustomerCreate : null}
                            />
                            <Resource
                                name={"users"}
                                list={permissions.role === "admin" ? UserList : null}
                                edit={UserEdit}
                                create={permissions.role === "admin" ? UserCreate : null}
                            />
                            <Resource name={"monitoredPortals"} list={permissions.role === "admin" ? MonitoredPortalsList : null} />
                            <CustomRoutes>
                                {permissions.role === "admin" && (
                                    <Route
                                        path="/admin/reviews"
                                        element={
                                            <Authenticated requireAuth>
                                                <ReportedReviews />
                                            </Authenticated>
                                        }
                                    />
                                )}
                                <Route
                                    path="/google-reviews"
                                    element={
                                        <Authenticated requireAuth>
                                            <CustomerReviews />
                                        </Authenticated>
                                    }
                                />
                                <Route
                                    path="/competitors"
                                    element={
                                        <Authenticated requireAuth>
                                            <Competitors />
                                        </Authenticated>
                                    }
                                />
                                {permissions.role === "user" && (
                                    <Route
                                        path="/customer-data"
                                        element={
                                            <Authenticated requireAuth>
                                                <div>Einstellungen - TODO -</div>
                                            </Authenticated>
                                        }
                                    />
                                )}
                                <Route
                                    path="/settings"
                                    element={
                                        <Authenticated requireAuth>
                                            <div>Einstellungen - TODO -</div>
                                        </Authenticated>
                                    }
                                />
                            </CustomRoutes>
                        </>
                    )}
                </Admin>
            </CompanyProvider>
        </UserProvider>
    </BrowserRouter>
);

export default App;
