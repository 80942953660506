import { Alert, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function GoogleLinkExplain() {
    return (
        <Alert severity="info" sx={{ mb: 2, width: "fit-content" }}>
            <Typography fontWeight={"bold"}>Wie erhalte ich den Google Link?</Typography>
            <Typography variant={"subtitle1"}>
                1.{" "}
                <Button
                    variant={"contained"}
                    size={"small"}
                    color={"secondary"}
                    target={"_blank"}
                    href={"https://www.google.de/maps"}
                    endIcon={<OpenInNewIcon />}
                >
                    Google Maps hier öffnen!
                </Button>
            </Typography>
            <Typography variant={"subtitle1"}>2. Nach dem gewählten Unternehmen suchen. Ggf. das Unternehmen links erst aussuchen.</Typography>
            <Typography variant={"subtitle1"}>
                3. Den Inhalt der <strong>kompletten</strong> Adressleiste kopieren und oben einfügen.
            </Typography>
            <Typography variant={"subtitle1"}>- Das richtige Format ist: "https://www.google.com/maps/place/...</Typography>
            <Typography variant={"subtitle1"}>
                <strong>Hinweis:</strong> Nach dem Einfügen ändert der Link sich automatisch in das richtige Format.
            </Typography>
        </Alert>
    );
}

export default GoogleLinkExplain;
