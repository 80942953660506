import {
    AutocompleteArrayInput,
    Create,
    email,
    minLength,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
} from "react-admin";
import { useCompanyContext } from "../../context/CompanyContext";
import { firebaseFunctions } from "../../App";
import { useState } from "react";

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label={"Benutzer erstellen"} disabled={props.isLoading} />
    </Toolbar>
);

/**
 * Wemm ein neuer Kunde hinzugefügt wird, dann muss über die firebase auth api der user angelegt werden. Siehe createUser für das anlegen neuer Kunden
 * Der neue User muss in firestore gespeichert werden
 */
function UserCreate() {
    const redirect = useRedirect();
    const notify = useNotify();
    const addNewUser = firebaseFunctions.httpsCallable("addNewUser");
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = (data) => {
        setIsLoading(true);
        console.log(data);
        addNewUser(data)
            .then((result) => {
                console.log(result);
                redirect("/users");
                notify("Neuer Benutzer erstellt", { type: "success", autoHideDuration: 5000 });
            })
            .catch((err) => {
                console.log(err);
                notify(err.message, { type: "error" });
            })
            .finally(() => setIsLoading(false));
    };
    const { companies } = useCompanyContext();
    // todo null values
    const optionRenderer = (choice) => `${choice.name}, ${choice?.zipCode}  ${choice?.city}`;

    const requiredValidate = required("Dieser Wert ist notwendig");
    return (
        <Create>
            <SimpleForm onSubmit={handleSubmit} toolbar={<CustomToolbar isLoading={isLoading} />}>
                <TextInput
                    source={"email"}
                    label={"E-Mail"}
                    validate={[requiredValidate, email("Dieser Wert muss eine gültige E-Mail Adresse sein")]}
                />
                <TextInput
                    source={"password"}
                    label={"Passwort"}
                    validate={[required(), minLength(8)]}
                    helperText={
                        <>
                            <strong>Wichtig!</strong> Mindestens 8 Zeichen. Das gesetze Passwort müssen Sie dem Benutzer gesondert mitteilen. Dies
                            geschieht <i>nicht</i> nur das System.
                        </>
                    }
                />
                <TextInput source={"firstName"} label={"Vorname"} validate={requiredValidate} />
                <TextInput source={"lastName"} label={"Nachname"} validate={requiredValidate} />
                <SelectInput
                    validate={requiredValidate}
                    source="role"
                    label={"Rolle"}
                    choices={[
                        { id: "user", name: "Benutzer" },
                        { id: "admin", name: "Admin" },
                    ]}
                />
                <AutocompleteArrayInput
                    helperText={"Einem Admin müssen keine Kunden zugeordnet werden."}
                    source="companies"
                    label={"Zugeordnete Kunden"}
                    choices={companies}
                    optionText={optionRenderer}
                />
                {/*<Typography component={"strong"}>Hinweise</Typography>
                <ul>
                    <Typography component={"li"}>
                        <strong>Wichtig!</strong> Das gesetze Passwort müssen Sie dem Benutzer gesondert mitteilen. Dies geschieht <i>nicht</i> nur
                        das System.
                    </Typography>
                </ul>*/}
            </SimpleForm>
        </Create>
    );
}

export default UserCreate;
