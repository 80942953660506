import { Alert, Box, Button } from "@mui/material";
import Notification from "./Notification";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { firestore } from "../../App";

function Notifications({ notifications, removeFromNotifications, setNotifications }) {
    const handleSetRead = (id) => {
        firestore
            .collection("notifications")
            .doc(id)
            .update({ read: true })
            .then(() => {
                removeFromNotifications(id);
            })
            .catch((e) => console.log(e.message));
    };

    const handleSetReadAll = () => {
        for (const not of notifications) {
            firestore.collection("notifications").doc(not.id).update({ read: true });
        }
        setNotifications([]);
    };

    return (
        <Box sx={{ py: 1 }}>
            {notifications.length > 0 ? (
                <>
                    <Box sx={{ textAlign: "right" }}>
                        <Button endIcon={<DoneAllIcon />} sx={{ mr: 1 }} onClick={handleSetReadAll}>
                            Alle als gelesen markieren
                        </Button>
                    </Box>
                    {notifications.map((notification) => (
                        <Notification
                            key={notification.id}
                            id={notification.id}
                            createdAt={notification.createdAt.seconds * 1000}
                            text={notification.text}
                            title={notification.title}
                            handleSetRead={handleSetRead}
                        />
                    ))}
                </>
            ) : (
                <Alert sx={{ mx: 1 }}>Es sind keine Benachrichtigungen vorhanden</Alert>
            )}
        </Box>
    );
}

export default Notifications;
