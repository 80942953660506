import { useEffect, useState } from "react";
import { firestore } from "../../App";
import { Alert, AlertTitle, Box, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useCompanyContext } from "../../context/CompanyContext";
import CustomerNotActiveAlert from "../Customers/CustomerNotActiveAlert";
import GoogleReviewCard from "./GoogleReviewCard";
import StatusColorDescription from "./StatusColorDescription";
import Filter from "./Filter";
import * as React from "react";

export const getBorderColor = (reportStatus) => {
    const border = "10px solid ";
    switch (reportStatus) {
        case "success":
            return border + "#34a853";
        case "inProgress":
            return border + "#fbbd04";
        case "process":
            return border + "#fbbd04";
        case "refused":
            return border + "#ea4336";
        case "reported":
            return border + "#a9a9a9";
        default:
            return border + "white";
    }
};

function CustomerReviews() {
    const queryLimit = 24;
    const [requestedQueryLimit, setRequestedQueryLimit] = useState(queryLimit);
    const { selectedCompany } = useCompanyContext();
    const [isLoading, setIsLoading] = useState(true);
    const [reviews, setReviews] = useState([]);
    const [last, setLast] = useState(null);
    const [starFilter, setStarFilter] = useState(null);
    const [atLawyerFilter, setAtLawyerFilter] = useState(null);

    let collectionReference = firestore.collection("reviews");

    useEffect(() => {
        async function fetchData() {
            setRequestedQueryLimit(queryLimit);
            setIsLoading(true);
            const collectionWrapper = await getCollectionWrapper();
            const collections = await collectionWrapper.orderBy("datetime", "desc").limit(queryLimit).get();
            const reviewsCache = [];

            for (const coll of collections.docs) {
                const collReviews = coll.data();
                reviewsCache.push(collReviews);
            }

            setLast(collections.docs[collections.size - 1]);
            setIsLoading(false);
            setReviews(reviewsCache);
        }

        if (selectedCompany) {
            fetchData();
        }
    }, [selectedCompany, starFilter, atLawyerFilter]);

    const handleLoadMoreReviews = async () => {
        const handleMoreLimit = 12;
        setRequestedQueryLimit(requestedQueryLimit + handleMoreLimit);
        const collectionWrapper = await getCollectionWrapper();
        const collections = await collectionWrapper.orderBy("datetime", "desc").startAfter(last).limit(handleMoreLimit).get();

        const reviewsCache = [];
        for (const coll of collections.docs) {
            const collReviews = coll.data();
            reviewsCache.push(collReviews);
        }

        setLast(collections.docs[collections.size - 1]);
        setReviews((prevState) => prevState.concat(reviewsCache));
    };

    const getCollectionWrapper = async () => {
        let collectionsFirstWrapper = await collectionReference.doc(selectedCompany.id).collection("google");
        if ((starFilter || atLawyerFilter) && !(starFilter && atLawyerFilter)) {
            if (starFilter) {
                return collectionsFirstWrapper.where("rating", "==", starFilter.toString());
            } else if (atLawyerFilter) {
                return collectionsFirstWrapper.where("reportStatus", "!=", null).orderBy("reportStatus", "asc");
            }
        } else if (starFilter && atLawyerFilter) {
            return collectionsFirstWrapper
                .where("reportStatus", "!=", null)
                .where("rating", "==", starFilter.toString())
                .orderBy("reportStatus", "asc");
        }
        return collectionsFirstWrapper;
    };

    if (selectedCompany?.active === false) {
        return <CustomerNotActiveAlert />;
    }

    return (
        <>
            <Box my={2}>
                <Paper elevation={3}>
                    <Filter
                        atLawyerFilter={atLawyerFilter}
                        setAtLawyerFilter={setAtLawyerFilter}
                        starFilter={starFilter}
                        setStarFilter={setStarFilter}
                    />
                </Paper>
                <Box py={2}>
                    <StatusColorDescription />
                </Box>
                {!isLoading && reviews.length > 0 && (
                    <>
                        <Grid container spacing={2}>
                            {reviews.length > 0 &&
                                reviews.map((review) => <GoogleReviewCard review={review} key={review.id} company={selectedCompany} />)}
                        </Grid>
                        {!isLoading && reviews.length >= requestedQueryLimit && (
                            <Box py={2} textAlign={"center"}>
                                <Button onClick={() => handleLoadMoreReviews()}>Mehr Bewertungen laden</Button>
                            </Box>
                        )}
                    </>
                )}
            </Box>
            {isLoading && (
                <Box mt={5} textAlign={"center"}>
                    Bewertungen werden geladen <CircularProgress />
                </Box>
            )}
            {!isLoading && reviews.length === 0 && (!atLawyerFilter || !starFilter) && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4, width: "100%" }}>
                    <Alert severity="warning">
                        <AlertTitle> Es gibt noch keine Bewertungen oder Sie haben noch keinen Google Link hinterlegt. </AlertTitle>
                        Sie können ihren Google Link unter "Kundendaten -> Portale" hinterlegen.
                    </Alert>
                </Box>
            )}
            {!isLoading && reviews.length === 0 && (atLawyerFilter || starFilter) && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4, width: "100%" }}>
                    <Alert severity="warning">
                        <AlertTitle> Es gibt keine Bewertungen mit dieser Filterung</AlertTitle>
                    </Alert>
                </Box>
            )}
        </>
    );
}

export default CustomerReviews;
