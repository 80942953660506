import { Alert, Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNotify } from "react-admin";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { auth, firebaseFunctions } from "../../App";
import { useRedirect } from "react-admin";

function Login({ setRegister }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [checkVerificationCode, setCheckVerificationCode] = useState(false);
    const [uid, setUid] = useState("");
    const [resetPassword, setResetPassword] = useState(false);
    const [errorResetPassword, setErrorResetPassword] = useState("");
    const [msgResetPassword, setMsgErrorResetPassword] = useState("");
    const [loginBtnName, setLoginBtnName] = useState("Login!");
    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const loginWith2FA = firebaseFunctions.httpsCallable("login");
    const checkEmailCode = firebaseFunctions.httpsCallable("checkEmailCode");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!resetPassword && !checkVerificationCode) {
            if (email && password) {
                setIsLoading(true);
                await loginWith2FA({ email, password })
                    .then(async (res) => {
                        if (res.data.twoFactorAuth) {
                            setLoginBtnName("Bestätigungscode prüfen!");
                            setCheckVerificationCode(true);
                            setUid(res.data.uid);
                        } else {
                            await auth.signInWithCustomToken(res.data.token);
                            redirect("/");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        notify("Deine E-Mail oder dein Passwort sind falsch", { type: "error" });
                    });
                setIsLoading(false);
            }
        } else if (!resetPassword && checkVerificationCode) {
            if (verificationCode) {
                setIsLoading(true);
                await checkEmailCode({ uid, verificationCode })
                    .then(async (res) => {
                        if (res.data.token) {
                            await auth.signInWithCustomToken(res.data.token);
                            redirect("/");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        notify("Der Bestätigungscode ist falsch", { type: "error" });
                    });
            }
        } else {
            setErrorResetPassword("");
            console.log("sending email to: ", email);
            try {
                setMsgErrorResetPassword("");
                await firebase.auth().sendPasswordResetEmail(email);
                setMsgErrorResetPassword("Eine E-Mail mit einem Link zum zurücksetzen des Passwortes wird Ihnen zugesandt.");
            } catch (error) {
                console.log(error);
                setErrorResetPassword(
                    (error.message = "EMAIL_NOT_FOUND" ? "E-Mail wurde nicht gefunden." : "Es gab einen Fehler, versuchen Sie es erneut."),
                );
            }
        }
    };

    return (
        <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{
                bgcolor: "rgba(255,255,255,0.95)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                borderRadius: 5,
                p: 5,
            }}
        >
            <Box component={"img"} sx={{ width: "25%", paddingBottom: { xs: 1, sm: 3 } }} src={"/star_protection.svg"} alt={"starprotection_logo"} />
            {!resetPassword ? (
                <>
                    <Typography
                        variant={"h5"}
                        textAlign={"center"}
                        fontWeight={"bold"}
                        borderBottom={"1px solid"}
                        width={"100%"}
                        pb={1}
                        mb={1}
                        sx={{ color: "#5e5e5e" }}
                    >
                        StarProtection - Ihr starkes Unternehmensimage
                    </Typography>
                    <Typography variant={"h6"} textAlign={"center"} width={"100%"} sx={{ color: "#5e5e5e" }} component={"i"} mb={2}>
                        Ihr Unternehmensruf wird durch das bestimmt, was Andere über Sie sagen.
                    </Typography>
                    {searchParams.get("registered") && <Alert>Sie sind jetzt registriert. Loggen Sie sich nun hier mit ihrem Password ein.</Alert>}
                    <Typography variant={"h6"} fontWeight={"bold"} alignSelf={"flex-start"} width={"100%"} pb={1} sx={{ color: "#5e5e5e" }}>
                        Login
                    </Typography>
                    <TextField
                        disabled={checkVerificationCode}
                        autoComplete={"email"}
                        name="email"
                        label="E-Mail"
                        variant="outlined"
                        type={"email"}
                        value={email}
                        fullWidth
                        inputProps={{ style: { backgroundColor: "#f6f9ff" } }}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Typography
                        onClick={() => setResetPassword(true)}
                        sx={{ alignSelf: "flex-end", mt: 1, "&:hover": { cursor: "pointer" } }}
                        variant={"caption"}
                    >
                        Passwort vergessen?
                    </Typography>
                    <TextField
                        disabled={checkVerificationCode}
                        sx={{ mt: 0 }}
                        autoComplete="password"
                        name="password"
                        label="Passwort"
                        variant="outlined"
                        type={"password"}
                        value={password}
                        fullWidth
                        inputProps={{ style: { backgroundColor: "#f6f9ff" } }}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {checkVerificationCode && (
                        <>
                            <Typography variant={"subtitle1"} sx={{ alignSelf: "flex-start", my: 1 }}>
                                Ein Bestätigungscode wurde an die E-Mail Adresse gesendet
                            </Typography>
                            <TextField
                                sx={{ mt: 0 }}
                                name="verificationCode"
                                label="E-Mail Bestätigungscode"
                                variant="outlined"
                                type={"verificationCode"}
                                value={verificationCode}
                                fullWidth
                                inputProps={{ style: { backgroundColor: "#f6f9ff" } }}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                        </>
                    )}
                    <Button
                        variant="contained"
                        startIcon={<PersonOutlineOutlinedIcon />}
                        disabled={isLoading}
                        type={"submit"}
                        sx={{ alignSelf: "flex-end", mt: 2 }}
                    >
                        {loginBtnName}
                        {isLoading && <CircularProgress sx={{ ml: 2 }} size={20} color={"warning"} />}
                    </Button>
                    <Divider sx={{ my: 2, width: "100%" }} />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Neu Hier?</Typography>
                        <Button onClick={() => setRegister(true)}>Jetzt Registrieren!</Button>
                    </Box>
                </>
            ) : (
                <>
                    <Typography variant={"h6"} fontWeight={"bold"} alignSelf={"flex-start"} width={"100%"} pb={1} sx={{ color: "#5e5e5e" }}>
                        Passwort vergessen?
                    </Typography>
                    {/*                    <Typography variant={"subtitle1"}>
                        Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen eine Anleitung zum Zurücksetzen Ihres Passwortes.
                    </Typography>*/}
                    <TextField
                        autoComplete={"email"}
                        name="email"
                        label="E-Mail"
                        variant="outlined"
                        type={"email"}
                        value={email}
                        fullWidth
                        inputProps={{ style: { backgroundColor: "#f6f9ff" } }}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errorResetPassword && <Typography color={"error"}>{errorResetPassword}</Typography>}
                    {msgResetPassword && <Alert sx={{ mt: 2 }}>{msgResetPassword}</Alert>}
                    <Button variant="contained" sx={{ alignSelf: "flex-end", mt: 2 }} type={"submit"} disabled={email.length === 0}>
                        Link zum Zurücksetzen senden
                    </Button>
                    <Divider sx={{ my: 2, width: "100%" }} />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Haben Sie bereits einen Zugang?</Typography>
                        <Button onClick={() => setResetPassword(false)}>Login!</Button>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default Login;
