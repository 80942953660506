import { Box, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

function LoginData({ handleNext, handleBack, formState, setFormState }) {
    const {
        watch,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        handleNext();
        setFormState((prev) => ({ ...prev, user: data }));
    };

    return (
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.user?.firstName}
                        name="firstName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.firstName} label={"Vorname *"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.user?.lastName}
                        name="lastName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.lastName} label={"Nachname *"} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        defaultValue={formState?.user?.email}
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.email} type={"email"} label={"E-Mail Adresse *"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.user?.password}
                        name="password"
                        control={control}
                        rules={{ required: true, minLength: 8 }}
                        render={({ field }) => (
                            <TextField {...field} error={!!errors?.password} label={"Passwort *"} helperText={"Mindestens 8 Zeichen. "} />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.user?.passwordRepeat}
                        name="passwordRepeat"
                        control={control}
                        rules={{ validate: (value, formValues) => value === watch("password") }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors?.passwordRepeat}
                                helperText={!!errors?.passwordRepeat && "Passwörter stimmen nicht überein"}
                                label={"Passwort Wiederholung *"}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", my: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type={"submit"}>Weiter</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default LoginData;
