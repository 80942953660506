import { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import { Alert, Avatar, Box, Button, CardActions, CardHeader, CircularProgress, Divider, Grid, Rating, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { Check, ReportGmailerrorredOutlined } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { SPIcon } from "../../layout/CustomMenu";
import CardContent from "@mui/material/CardContent";
import { getBorderColor } from "./Reviews";
import { firebaseFunctions } from "../../App";

function GoogleReviewCard({ review, company = null }) {
    const [showAll, setShowAll] = useState(false);
    const [reportStatus, setReportStatus] = useState(review.reportStatus);
    const [reportedAt, setReportedAt] = useState(review.reportedAt);
    const [displayInfo, setDisplayInfo] = useState(false);
    const [notes, setNotes] = useState("");
    const [openReport, setOpenReport] = useState(false);
    const [sendingReview, setSendingReview] = useState(false);
    const { user } = useUserContext();
    const reportReviewToLawyer = firebaseFunctions.httpsCallable("reportReviewToLawyer");
    // todo photos einbinden -> review.photos -> zb. 4449202949291128684-1655735831.11 -> company -> 1BseHNRTVtWxOFOfGnAx4Ex4iQTx
    /**
     *     - Grün = erfolgreich -> success
     *     - Gelb = in Bearbeitung -> inProgress
     *     - Rot = Löschung verweigert -> refused
     *     - Grau = Gemeldet -> reported
     */

    const checkIfNew = () => {
        const reviewFetchedAt = review.fetchedAt;
        const userTimeStamp = parseInt(user.lastLogin);
        if (reviewFetchedAt > userTimeStamp.toFixed(0)) {
            return true;
        }
    };

    const reportReview = () => {
        if (!company) return;
        setSendingReview(true);
        const data = {
            review,
            user: { firstName: user.firstName, lastName: user.lastName },
            company: { id: company.id, name: company.name },
            notes: notes.length > 0 ? notes : null,
        };
        reportReviewToLawyer(data)
            .then((res) => {
                setReportStatus("inProgress");
                setReportedAt(new Date().toString());
                setDisplayInfo(true);
                setOpenReport(false);
            })
            .catch((err) => {
                console.log(data);
                console.log(err);
            })
            .finally(() => {
                setSendingReview(false);
            });
    };

    return (
        <Grid item xs={12} lg={6} xl={4}>
            <Box sx={{ position: "relative", height: "100%" }}>
                {checkIfNew() && (
                    <Box className={"newReviewCorner"} sx={{ position: "absolute" }}>
                        <span>NEU!</span>
                    </Box>
                )}
                <Card
                    sx={{
                        height: "100%",
                        borderTop: getBorderColor(reportStatus),
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    {displayInfo && (
                        <Alert
                            sx={{ mx: 2, mt: 2 }}
                            severity="success"
                            onClose={() => {
                                setDisplayInfo(false);
                            }}
                        >
                            Bewertung wurde an den Anwalt gemeldet.
                        </Alert>
                    )}
                    <Box>
                        <CardHeader
                            sx={{ pb: 1, pt: 1, "& .MuiIcon-root": { fontSize: 40 }, "& .MuiCardHeader-action": { mt: 0 } }}
                            avatar={<Avatar src={review?.reviewer_avatar} aria-label="avatar" />}
                            action={reportStatus && <SPIcon />}
                            title={review?.reviewer}
                            subheader={new Date(review.datetime).toLocaleString("de-DE", { dateStyle: "long", timeStyle: "short" }) + " Uhr"}
                        />
                        <CardContent sx={{ pt: 0, pb: "16px !important" }}>
                            <Rating name="read-only" value={review?.rating ? parseInt(review?.rating) : 0} readOnly sx={{ pb: 1 }} />
                            <Typography variant="body2" color="text.secondary">
                                {review?.text.length < 250 || showAll ? review?.text : review?.text.slice(0, 250) + " ..."}
                                {review?.text.length > 250 && !showAll && (
                                    <Typography
                                        sx={{ cursor: "pointer" }}
                                        pl={1}
                                        color={"primary"}
                                        component={"span"}
                                        variant={"subtitle2"}
                                        onClick={() => setShowAll(true)}
                                    >
                                        Alles anzeigen
                                    </Typography>
                                )}
                            </Typography>
                        </CardContent>
                    </Box>
                    <Box>
                        {openReport && (
                            <Box p={2}>
                                <Typography variant={"h6"}>Bewertung an den Anwalt melden</Typography>
                                <Divider />
                                <Box sx={{ position: "relative", mt: 2 }}>
                                    <TextField
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                        sx={{ width: "100% !important", m: 0 }}
                                        multiline
                                        rows={3}
                                        helperText={"Hier haben Sie die Möglichkeit Notizen für den Anwalt zu hinterlegen."}
                                        label={"Notizen (optional)"}
                                        inputProps={{ maxLength: 500 }}
                                    />
                                    <Box sx={{ position: "absolute", bottom: 30, right: 15, color: "#797979" }}>{`${notes.length} / 500`}</Box>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1, px: 1 }}>
                                    <Button variant={"outlined"} onClick={() => setOpenReport(false)}>
                                        Abbrechen
                                    </Button>
                                    <Button variant={"contained"} disabled={sendingReview} onClick={reportReview}>
                                        Bewertung jetzt melden! {sendingReview && <CircularProgress size={20} sx={{ ml: 1 }} />}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                        <Divider />
                        <CardActions sx={{ justifyContent: "space-between", backgroundColor: "#f2f2f2" }}>
                            {company && (
                                <Box>
                                    {!reportStatus ? (
                                        <Button
                                            endIcon={<ReportGmailerrorredOutlined color={"action"} />}
                                            onClick={() => setOpenReport(true)}
                                            sx={{ "& > span": { ml: "2px" }, display: openReport && "none" }}
                                        >
                                            Bewertung melden
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled
                                            endIcon={<Check color={"error"} />}
                                            sx={{ color: "#484848 !important", "& > span": { ml: "2px" } }}
                                        >
                                            Gemeldet am&nbsp;
                                            {new Date(reportedAt).toLocaleString("de-DE", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                            })}
                                        </Button>
                                    )}
                                </Box>
                            )}
                            <Button sx={{ color: "#484848", mr: 2 }} endIcon={<OpenInNewIcon />} href={review.url} target={"_blank"}>
                                Zur Bewertung
                            </Button>
                        </CardActions>
                    </Box>
                </Card>
            </Box>
        </Grid>
    );
}

export default GoogleReviewCard;
