import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import { Alert, AlertTitle, Box, CircularProgress, Divider, Grid, Rating, Typography } from "@mui/material";
import { useCompanyContext } from "../context/CompanyContext";
import { PieChart } from "react-minimal-pie-chart";
import { firestore } from "../App";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CustomerNotActiveAlert from "./Customers/CustomerNotActiveAlert";
import StatusColorDescription from "./CustomerReviews/StatusColorDescription";
import GoogleReviewCard from "./CustomerReviews/GoogleReviewCard";
import * as React from "react";

export const getPieChartColor = (title) => {
    switch (title) {
        case "1":
            return "#a9a9a9";
        case "2":
            return "#ea4336";
        case "3":
            return "#fbbd04";
        case "4":
            return "#34a853";
        case "5":
            return "#4385f4";
        default:
            break;
    }
};
const Dashboard = () => {
    const [distribution, setDistribution] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [lastReviews, setLastReviews] = useState([]);
    const { selectedCompany } = useCompanyContext();

    useEffect(() => {
        if (selectedCompany) fetchData();
    }, [selectedCompany]);

    async function fetchData() {
        setIsLoading(true);
        const collectionReference = firestore.collection("reviews").doc(selectedCompany.id).collection("google");
        const collections = await collectionReference.limit(12).orderBy("datetime", "desc").get();
        const reviewsCache = [];
        if (collections.empty) {
            // todo handle no reviews
        } else {
            //console.log("get reviews collections", collections.size);
            for (const coll of collections.docs) {
                const collReviews = coll.data();
                reviewsCache.push(collReviews);
            }
        }
        setIsLoading(false);
        setLastReviews(reviewsCache);

        //console.log(typeof selectedCompany?.apis?.google?.date);

        const distributionCache = [];
        if (selectedCompany.apis?.google?.distribution) {
            Object.keys(selectedCompany.apis.google.distribution).map((key) => {
                distributionCache.push({
                    title: key.toString(),
                    value: selectedCompany.apis.google.distribution[key],
                    color: getPieChartColor(key.toString()),
                });
            });
            setDistribution(distributionCache);
        } else {
            setDistribution([]);
        }
    }

    if (selectedCompany?.active === false) {
        return <CustomerNotActiveAlert />;
    }

    return (
        <Grid container spacing={2} sx={{ p: 2 }}>
            {isLoading ? (
                <Box mt={5} width={"100%"} textAlign={"center"}>
                    Dashboard wird geladen <CircularProgress />
                </Box>
            ) : (
                <>
                    {lastReviews.length > 0 ? (
                        <>
                            {" "}
                            <Grid item xs={12}>
                                <img src={"/google_logo.svg"} height={50} />
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "end" }}>
                                <Divider sx={{ width: "100%" }} />
                            </Grid>
                            <Analytics distribution={distribution} selectedCompany={selectedCompany} />
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "end" }}>
                                <Typography variant={"h6"} sx={{ ml: 1, mb: 0.5 }}>
                                    Aktuellste Bewertungen
                                    {/* (Aktuellste Bewertung vom{" "}
                            {typeof selectedCompany?.apis?.google?.date == "string"
                                ? new Date(selectedCompany?.apis?.google?.date).toLocaleString("de-DE", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                  })
                                : selectedCompany?.apis?.google?.date
                                      ?.toDate()
                                      .toLocaleString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}
                            )*/}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "end" }}>
                                <Divider sx={{ width: "100%" }} />
                            </Grid>
                            <Grid item xs={12}>
                                <StatusColorDescription />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {lastReviews.map((review) => (
                                        <GoogleReviewCard review={review} key={review.id} company={selectedCompany} />
                                    ))}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, width: "100%" }}>
                            <Alert severity="warning">
                                <AlertTitle> Es gibt noch keine Bewertungen oder Sie haben noch keinen Google Link hinterlegt. </AlertTitle>
                                Sie können ihren Google Link unter "Kundendaten -> Portale" hinterlegen.
                            </Alert>
                        </Box>
                    )}
                </>
            )}
        </Grid>
    );
};

export function Analytics({ selectedCompany, distribution, competitor = false }) {
    let averageRating = 0.0;
    let reviewCount = 0;
    if (competitor) {
        averageRating = parseFloat(selectedCompany?.Google?.averageRating);
        reviewCount = selectedCompany?.Google?.counterReviews;
    } else {
        averageRating = isNaN(parseFloat(selectedCompany?.apis?.google?.totals?.average_rating))
            ? parseFloat(selectedCompany?.apis?.google?.averageRating)
            : parseFloat(selectedCompany?.apis?.google?.totals?.average_rating);
        reviewCount = isNaN(parseInt(selectedCompany?.apis?.google?.totals?.review_count))
            ? parseInt(selectedCompany?.apis?.google?.counterReviews)
            : parseInt(selectedCompany?.apis?.google?.totals?.review_count);
    }
    return (
        <>
            <Grid item xs={12} lg={4}>
                <Card sx={{ height: "100%" }}>
                    <CardContent sx={{ height: "100%" }}>
                        <Box sx={{ height: "100%" }} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant={"h1"}>
                                {averageRating}
                                <img src={"/average.svg"} height={50} style={{ marginBottom: "-6px" }} />
                            </Typography>
                            <Rating name="read-only" value={averageRating} precision={0.1} readOnly size="large" />
                            <Typography variant={"h4"}>{reviewCount} Bewertungen</Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={8} sx={{ maxHeight: "35vh", p: 2 }}>
                                <PieChart
                                    data={distribution}
                                    lineWidth={20}
                                    label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
                                    labelStyle={{ fontSize: "5px" }}
                                    labelPosition={65}
                                    paddingAngle={15}
                                    rounded
                                />
                                {/*https://toomuchdesign.github.io/react-minimal-pie-chart/index.html?path=/story/labels--custom-label-element*/}
                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                {distribution &&
                                    [...distribution].reverse().map((item) => (
                                        <Typography sx={{ py: 1 }} key={item.title}>
                                            <Brightness1Icon sx={{ color: item.color, verticalAlign: "middle", mr: 1 }} />
                                            {item.title} Sterne - {item.value} Bewertungen
                                        </Typography>
                                    ))}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default Dashboard;
