import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, FilledInput, Select } from "@mui/material";

function StatusFilter({ statusFilter, setStatusFilter }) {
    const reviewStatus = [
        { id: "reported", label: "Gemeldet" },
        { id: "inProgress", label: "In Bearbeitung" },
        { id: "success", label: "Erfolgreich" },
        { id: "refused", label: "Löschung verweigert" },
    ];
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setStatusFilter(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value,
        );
    };

    // const renderSelectedStatus = (selected) => selected.map(sel => reviewStatus.find(s => s.id === sel).label).join(", ");

    const renderSelectedStatus = (selected) => {
        const selectedLabels = selected.map((sel) => {
            const found = reviewStatus.find((s) => s.id === sel);
            return found.label;
        });

        return selectedLabels.join(", ");
    };

    return (
        <FormControl
            sx={{ m: 1, width: "300px !important", pb: 0.5, "& .MuiInputBase-root": { borderBottom: statusFilter.length > 0 && "2px solid red" } }}
        >
            <InputLabel id="status-filter-label">Status filtern</InputLabel>
            <Select
                labelId="status-filter-label"
                id="demo-multiple-checkbox"
                multiple
                value={statusFilter}
                onChange={handleChange}
                input={<FilledInput label="Status filtern" />}
                renderValue={renderSelectedStatus}
            >
                {reviewStatus.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                        <Checkbox checked={statusFilter.indexOf(status.id) > -1} />
                        <ListItemText primary={status.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default StatusFilter;
