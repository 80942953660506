import { createContext, useContext, useEffect, useState } from "react";
import { authProvider, firestore } from "../App";

const UserContext = createContext({});
export default function UserProvider({ children }) {
    const [user, setUser] = useState({});

    useEffect(() => {
        loadUser();
    }, []);

    const loadUser = async () => {
        const user = await authProvider.checkAuth();
        if (user) {
            console.log("user last login", user.metadata.lastLoginAt);
            const doc = await firestore.collection("users").doc(user.uid).get();
            const userData = doc.data();
            userData.id = doc.id;
            userData.lastLogin = user.metadata.lastLoginAt;
            if (doc.data()) setUser(userData);
        }
    };

    return <UserContext.Provider value={{ user, loadUser }}>{children}</UserContext.Provider>;
}

export const useUserContext = () => useContext(UserContext);
