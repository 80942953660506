import { Box, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";

function SaveCircularProgress() {
    return (
        <Box p={1} textAlign={"center"}>
            Speichern
            <CircularProgress size={20} sx={{ ml: 1 }} />
        </Box>
    );
}

export default SaveCircularProgress;
