import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import { Controller, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { firebaseFunctions } from "../../../App";
import { useNavigate } from "react-router-dom";

// https://stackoverflow.com/questions/62291962/react-hook-forms-material-ui-checkboxes
function Agreement({ handleBack, handleNext, formState, setFormState, setRegister }) {
    const navigate = useNavigate();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [agreements, setAgreements] = useState({ agb: false, dataProcessing: false });
    const [error, setError] = useState(null);
    const addNewCompany = firebaseFunctions.httpsCallable("addNewCompany");
    const onSubmit = (event) => {
        event.preventDefault();
        addNewCompany({ ...formState, withoutUser: false, selfRegister: true })
            .then((result) => {
                console.log(result);
                navigate("/login?registered=y");
                setRegister(false);
            })
            .catch((err) => {
                console.log(err);
                setError(err.message);
            });
        console.log(JSON.stringify(formState));
    };

    const handleCheck = (e) => {
        setAgreements({
            ...agreements,
            [e.target.name]: e.target.checked,
        });
    };

    useEffect(() => {
        const exist = Object.values(agreements).includes(false);
        setSubmitDisabled(exist);
    }, [agreements]);

    return (
        <Box component={"form"} onSubmit={onSubmit}>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox name={"agb"} onChange={handleCheck} checked={agreements.agb} />}
                    label={
                        <>
                            Ich stimme den AGB zu *
                            <IconButton href={"/agreements/agb.pdf"} target={"blank"}>
                                <ArticleIcon />
                            </IconButton>
                        </>
                    }
                />
                <FormControlLabel
                    control={<Checkbox name={"dataProcessing"} onChange={handleCheck} checked={agreements.dataProcessing} />}
                    label={
                        <>
                            Ich stimme der Datenverarbeitung zu *
                            <IconButton href={"/agreements/dataProcessing.pdf"} target={"blank"}>
                                <ArticleIcon />
                            </IconButton>
                        </>
                    }
                />
                {/*                <FormControlLabel
                    control={<Checkbox name={"dataSecurity"} onChange={handleCheck} checked={agreements.dataSecurity} />}
                    label={
                        <>
                            Ich stimme den Datenschutzbestimmungen zu *
                            <IconButton href={"/agreements/dataSec.pdf"} target={"blank"}>
                                <ArticleIcon />
                            </IconButton>
                        </>
                    }
                />*/}
            </FormGroup>
            <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", my: 2 }}>
                <Button color={"inherit"} onClick={handleBack} sx={{ mr: 1 }}>
                    Zurück
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button variant={"contained"} type={"submit"} disabled={submitDisabled}>
                    <strong>Jetzt kostenpflichtig für 24,95€ (zzgl. MwSt.) bestellen!</strong>
                </Button>
            </Grid>
            <Grid item xs={12}>
                {error && <Typography color={"error"}>{error}</Typography>}
            </Grid>
        </Box>
    );
}

export default Agreement;
