import {
    ArrayInput,
    BooleanInput,
    Create,
    email,
    FormDataConsumer,
    FormTab,
    maxLength,
    minLength,
    number,
    NumberInput,
    required,
    SaveButton,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
} from "react-admin";
import { Alert, Button, Divider, FormControlLabel, Switch, Typography } from "@mui/material";
import { firebaseFunctions } from "../../App";
import { useState } from "react";
import { useCompanyContext } from "../../context/CompanyContext";
import { Add, Delete } from "@mui/icons-material";
import GoogleLinkExplain from "./GoogleLinkExplain";
import { useFormContext } from "react-hook-form";
import Portals from "./Portals/Portals";
import Competitors from "./Competitors";

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton
            disabled={props.googleLinkError || props.isLoading}
            label={!props.withoutUser ? "Benutzer & Kunde erstellen" : "Kunde erstellen"}
        />
    </Toolbar>
);

export const disableAddCompetitors = (formData) => {
    return formData.competitors?.length > 2;
};
export const parseGoogleLink = (value) => {
    return value == null ? "" : value.replace("https://www.google.com/maps/place/", "").replace("https://www.google.de/maps/place/", "");
};
function CustomerCreate() {
    const addNewCompany = firebaseFunctions.httpsCallable("addNewCompany");
    const notify = useNotify();
    const redirect = useRedirect();
    const [withoutUser, setWithoutUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { loadCompanies } = useCompanyContext();
    const [googleLinkError, setGoogleLinkError] = useState(false);
    const requiredValidate = required("Dieser Wert ist notwendig");
    const requiredValidateWithoutUser = !withoutUser ? required("Dieser Wert ist notwendig") : null;

    const handleSubmit = (data) => {
        console.log(data);
        setIsLoading(true);
        addNewCompany({ ...data, withoutUser })
            .then((result) => {
                console.log(result);
                redirect("/companies");
                notify("Neuer Kunde erstellt", { type: "success", autoHideDuration: 5000 });
                loadCompanies();
            })
            .catch((err) => {
                console.log(err);
                notify(err.message, { type: "error" });
            })
            .finally(() => setIsLoading(false));
    };

    const parseGoogleLinkWrapper = (value) => {
        setGoogleLinkError(false);
        //https://www.google.de/maps/place/DAA+Weiden+Fitnesstudio+Die+Abnehm+Akademie/@49.6923834,12.1340813,17z/data=!3m1!4b1!4m6!3m5!1s0x47a03868564209bb:0xc883f46991760170!8m2!3d49.69238!4d12.13627!16s%2Fg%2F1tf6911f
        if (value.length > 0 && (value.includes("https://www.google.com/maps/place/") || value.includes("https://www.google.de/maps/place/"))) {
            return parseGoogleLink(value);
        } else if (value.length === 0) {
            setGoogleLinkError(false);
            return value;
        } else {
            setGoogleLinkError(true);
            return value;
        }
    };

    return (
        <Create>
            <TabbedForm
                onSubmit={handleSubmit}
                toolbar={<CustomToolbar withoutUser={withoutUser} googleLinkError={googleLinkError} isLoading={isLoading} />}
            >
                <FormTab label="Benutzer" name="user">
                    <FormControlLabel
                        control={<Switch value={withoutUser} onChange={() => setWithoutUser(!withoutUser)} />}
                        label={<strong>Kunde ohne Benutzer anlegen?</strong>}
                    />
                    <Typography>
                        <Alert severity={"info"}>
                            Dies ist der Fall wenn einem bestehendem Benutzer ein Kunde zugeordnet werden soll. Diese Zuordnung erfolgt im Menü Punkt
                            Benutzer.
                        </Alert>
                    </Typography>
                    <Divider sx={{ my: 2, width: "100%" }} />
                    <TextInput disabled={withoutUser} label="E-Mail" source="user.email" validate={requiredValidateWithoutUser} type={"email"} />
                    <TextInput disabled={withoutUser} label="Passwort" source="user.password" validate={requiredValidateWithoutUser} />
                    <TextInput disabled={withoutUser} label="Vorname" source="user.firstName" validate={requiredValidateWithoutUser} />
                    <TextInput disabled={withoutUser} label="Nachname" source="user.lastName" validate={requiredValidateWithoutUser} />
                    <Alert severity={"info"}>
                        <Typography component={"strong"}>Hinweise</Typography>
                        <ul>
                            <Typography component={"li"}>
                                Dieser Benutzer ist der erste Benutzer des Kunden, weitere Benutzer für ein Kunde können unter dem Menü Punkt Benutzer
                                angelegt werden.
                            </Typography>
                            <Typography component={"li"}>
                                <strong>Wichtig!</strong> Das gesetze Passwort müssen Sie dem Benutzer gesondert mitteilen. Dies geschieht{" "}
                                <i>nicht</i> nur das System.
                            </Typography>
                        </ul>
                    </Alert>
                </FormTab>
                <FormTab label="Kunde" name="company">
                    <TextInput label="Kundenname" source="company.name" validate={requiredValidate} />
                    <TextInput label="Straße & Hausnummer" source="company.address" />
                    <TextInput
                        label="Postleitzahl"
                        source="company.zipCode"
                        validate={[number("Muss einen Nummer sein"), minLength(4), maxLength(5)]}
                    />
                    <TextInput label="Stadt" source="company.city" />
                    <TextInput label="E-Mail des Kunden" source="company.email" type={"email"} validate={email()} />
                    <TextInput label="Telefonnummer des Kunden" source="company.phoneNumber" />
                    <TextInput label="Webseite" source="company.website" />
                    <TextInput label="Name der Kontaktperson" source="company.contactName" />
                </FormTab>
                <FormTab label="Zahlungsdaten" name="payment">
                    <TextInput label="Kontoinhaber" source="billingInfos.accountHolder" />
                    <TextInput label="IBAN" source="billingInfos.iban" />
                    <TextInput label="BIC" source="billingInfos.bic" />
                    <TextInput label="Bankname" source="billingInfos.financeInstitute" />
                </FormTab>
                <FormTab label="Portale" name="apis">
                    <Portals />
                </FormTab>
                <FormTab label="Mitbewerber" name="competitors">
                    <Competitors />
                </FormTab>
            </TabbedForm>
        </Create>
    );
}

export default CustomerCreate;
