import { Button, Typography } from "@mui/material";
import { ArrayInput, FormDataConsumer, required, SimpleFormIterator, TextInput } from "react-admin";
import { Add, Delete } from "@mui/icons-material";
import GoogleLinkExplain from "./GoogleLinkExplain";
import { parseGoogleLink } from "./Create";
import { useState } from "react";

function Competitors() {
    const disableAddCompetitors = (formData) => {
        return formData.competitors?.length > 2;
    };
    const [googleLinkError, setGoogleLinkError] = useState(false);
    const parseGoogleLinkWrapper = (value) => {
        setGoogleLinkError(false);
        //https://www.google.de/maps/place/DAA+Weiden+Fitnesstudio+Die+Abnehm+Akademie/@49.6923834,12.1340813,17z/data=!3m1!4b1!4m6!3m5!1s0x47a03868564209bb:0xc883f46991760170!8m2!3d49.69238!4d12.13627!16s%2Fg%2F1tf6911f
        if (value.length > 0 && (value.includes("https://www.google.com/maps/place/") || value.includes("https://www.google.de/maps/place/"))) {
            return parseGoogleLink(value);
        } else if (value.length === 0) {
            setGoogleLinkError(false);
            return value;
        } else {
            setGoogleLinkError(true);
            return value;
        }
    };

    return (
        <>
            <Typography>Fügen Sie bis zu 3 Mitbewerber hinzu, die Sie mitverfolgen möchten.</Typography>
            <FormDataConsumer>
                {({ formData }) => (
                    <ArrayInput source="competitors" label="Mitbewerber">
                        <SimpleFormIterator
                            disableReordering
                            disableClear
                            disableAdd={disableAddCompetitors(formData)}
                            getItemLabel={(index) => `${index + 1}.`}
                            addButton={
                                <Button size={"small"} endIcon={<Add />}>
                                    Hinzufügen
                                </Button>
                            }
                            removeButton={
                                <Button size={"small"} endIcon={<Delete />}>
                                    Löschen
                                </Button>
                            }
                        >
                            <TextInput source="companyName" label={"Unternehmensname"} validate={required()} />
                            <TextInput source="Google.link" label={"Google Link"} validate={required()} parse={parseGoogleLinkWrapper} />
                        </SimpleFormIterator>
                    </ArrayInput>
                )}
            </FormDataConsumer>
            <GoogleLinkExplain />
        </>
    );
}

export default Competitors;
