import {
    CreateButton,
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField,
    TextInput,
    TopToolbar,
    SimpleShowLayout,
    SelectInput,
    AutocompleteInput,
} from "react-admin";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useEffect, useState } from "react";
import { firestore } from "../../App";
import { useCompanyContext } from "../../context/CompanyContext";

const filters = [
    <AutocompleteCustomerName alwaysOn />,
    <SelectInput
        label="Status"
        source="active"
        choices={[
            { id: true, name: "Aktiv" },
            { id: false, name: "Inaktiv" },
        ]}
        alwaysOn
    />,
];

function AutocompleteCustomerName() {
    const { companies } = useCompanyContext();
    const optionRenderer = (choice) => `${choice.name}, ${choice.zipCode} ${choice.city}`;
    return (
        <AutocompleteInput
            sx={{ width: "500px !important" }}
            source="name"
            label={"Kundenname"}
            choices={companies}
            optionValue="name"
            optionText={optionRenderer}
        />
    );
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton label={"Neuer Kunde"} size={"large"} alignIcon={"right"} />
    </TopToolbar>
);

const ExpandPanel = () => {
    return (
        <Box>
            <Typography sx={{ textDecoration: "underline" }}>Google Bewertung Übersicht</Typography>
            <SimpleShowLayout sx={{ "& > .MuiStack-root": { flexDirection: "row", alignItems: "baseline", "& > *": { mx: 1 } } }}>
                <TextField source={"apis.google.counterReviews"} label={"Bewertungen"} />
                <TextField source={"apis.google.averageRating"} label={"Durchschnitt"} />
                <FunctionField
                    source={"apis.google.automaticForwarding"}
                    label={"Autom. Weiterl."}
                    render={(record) => <AutomaticForwardingField record={record} />}
                />
                {/*<TextField source={"apis.google.automaticForwardingLimit"} label={"Limit"} />*/}
                {/*TODO ersetzen durch apis.google.lastReviewTimestamp + ändern in datum vom timestamp*/}
                <DateField
                    source={"apis.google.lastReviewTimestamp"}
                    label={"Aktl. Bewertung vom"}
                    transform={(value) => new Date(value * 1000)}
                    options={{ year: "numeric", month: "2-digit", day: "2-digit" }}
                />
            </SimpleShowLayout>
        </Box>
    );
};

function CustomerList() {
    const [portals, setPortals] = useState([]);

    useEffect(() => {
        loadPortals();
    }, []);

    const loadPortals = async () => {
        const portalCache = [];
        const querySnapshot = await firestore.collection("monitoredPortals").get();
        querySnapshot.forEach((doc) => {
            const portal = doc.data();
            portal.id = doc.id;
            portalCache.push(portal);
        });
        portalCache.sort((a, b) => new Date(a.createdate.seconds * 1000) - new Date(b.createdate.seconds * 1000));
        setPortals(portalCache);
    };
    return (
        <List perPage={50} actions={<ListActions />} filters={filters} sort={{ field: "name", order: "ASC" }}>
            <Datagrid
                bulkActionButtons={false}
                rowClick="edit"
                sx={{
                    "& .RaDatagrid-rowOdd": {
                        backgroundColor: "rgb(245,245,245)",
                    },
                    "& .RaDatagrid-rowCell": {
                        py: 1.5,
                    },
                }}
                expand={<ExpandPanel />}
                expandSingle
            >
                <FunctionField sortable={false} source={"name"} label={"Kunde"} render={(record) => <NameField record={record} />} />
                <FunctionField sortable={false} source={"city"} label={"Adresse"} render={(record) => <AddressField record={record} />} />
                <FunctionField sortable={false} source={"email"} label={"Kontakt"} render={(record) => <ContactField record={record} />} />
                <FunctionField sortable={false} label={"Webseite"} render={(record) => <WebField record={record} />} />
                {portals.length > 0 &&
                    portals.map((portal) => (
                        <FunctionField
                            sortable={false}
                            source={`apis.${portal.id}.active`}
                            label={portal.name}
                            render={(record) => <PortalField record={record} id={portal.id} />}
                        />
                    ))}
                <DateField
                    sortable={false}
                    source={"createDate"}
                    label={"Erstellt am"}
                    options={{ year: "numeric", month: "2-digit", day: "2-digit" }}
                />
                <FunctionField sortable={false} source={"recommendedBy"} label={"Geworben"} render={(record) => <RecommendedBy record={record} />} />
            </Datagrid>
        </List>
    );
}

function NameField({ record }) {
    return (
        <Box sx={{ borderLeftStyle: "solid", borderLeftWidth: 6, borderLeftColor: record.active ? "green" : "red", pl: 1 }}>
            {record["name"]} <br />
            <Typography variant={"caption"}>{record["contactName"]}</Typography>
        </Box>
    );
}
function WebField({ record }) {
    return (
        record["website"] && (
            <IconButton aria-label="to_web" href={"//" + record["website"]} target={"_blank"} onClick={(event) => event.stopPropagation()}>
                <LanguageOutlinedIcon />
            </IconButton>
        )
    );
}

function RecommendedBy({ record }) {
    return record.recommendedBy ? <CheckCircleOutlineIcon color={"success"} /> : <HighlightOffOutlinedIcon color={"error"} />;
}

function AutomaticForwardingField({ record }) {
    if (record["apis"]) {
        if ("google" in record["apis"]) {
            if ("automaticForwarding" in record["apis"]["google"]) {
                if (record["apis"]["google"]["automaticForwarding"]) {
                    return <CheckCircleOutlineIcon color={"success"} />;
                } else {
                    return <HighlightOffOutlinedIcon color={"error"} />;
                }
            }
        }
    }
    return <></>;
}

function AddressField({ record }) {
    return (
        <>
            {record["address"]} <br />
            {record["zipCode"]} {record["city"]}
        </>
    );
}

function ContactField({ record }) {
    //{record["contactInfos"]["website"]?.slice(0, 35) + "..."}
    return (
        <>
            {record["email"]}
            {/*            {
                <a href={"//" + record["website"]} target={"_blank"}>
                    Zur Webseite
                </a>
            }*/}
            <br />
            {record["phoneNumber"]}
        </>
    );
}

function PortalField({ record, id }) {
    return record?.apis?.[id]?.active ? <CheckCircleOutlineIcon color={"success"} /> : <HighlightOffOutlinedIcon color={"error"} />;
}

export default CustomerList;
