import { Box, Checkbox, Divider, FormControlLabel, IconButton, Rating } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Clear } from "@mui/icons-material";
import { SPIcon } from "../../layout/CustomMenu";

function Filter({ atLawyerFilter, setAtLawyerFilter, starFilter, setStarFilter }) {
    return (
        <Box p={1} sx={{ display: "flex", alignItems: "center", p: 1, "& > div": { px: 2, py: 1, display: "flex", alignItems: "center" } }}>
            <Box>
                <FilterAltOutlinedIcon />
                <Rating name="read-only" value={starFilter} onChange={(event, newValue) => setStarFilter(newValue)} />
                {starFilter && (
                    <IconButton aria-label="delete" onClick={() => setStarFilter(null)}>
                        <Clear />
                    </IconButton>
                )}
            </Box>
            <Divider orientation={"vertical"} flexItem />
            <Box>
                <FormControlLabel
                    control={<Checkbox checked={atLawyerFilter} onChange={(e) => setAtLawyerFilter(e.target.checked)} />}
                    label={
                        <Box display={"flex"} sx={{ "& > span": { ml: 1 } }}>
                            Beim Anwalt <SPIcon />
                        </Box>
                    }
                />
            </Box>
        </Box>
    );
}

export default Filter;
