import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    CardActions,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Popover,
    Rating,
    TextField,
    Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import { firebaseFunctions, firestore } from "../../App";
import { getBorderColor } from "../CustomerReviews/Reviews";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveCircularProgress from "../SaveCircularProgress";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Check, CopyAll, EditNote } from "@mui/icons-material";
import StatusColorDescription from "../CustomerReviews/StatusColorDescription";
import CompanyFilter from "./CompanyFilter";
import StatusFilter from "./StatusFilter";

function ReportedReviews() {
    const queryLimit = 50;
    const [reportedReviews, setReportedReviews] = useState([]);
    const [requestedQueryLimit, setRequestedQueryLimit] = useState(queryLimit);
    const [isLoading, setIsLoading] = useState(false);
    const [last, setLast] = useState(null);
    const [companyFilter, setCompanyFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(["reported", "inProgress"]);
    const collectionReference = firestore.collection("reportedReviews");

    useEffect(() => {
        async function fetchData() {
            setRequestedQueryLimit(queryLimit);
            setIsLoading(true);

            let collections;

            if (statusFilter.length > 0) {
                collections = !companyFilter
                    ? await collectionReference.orderBy("reportedAt", "desc").where("reportStatus", "in", statusFilter).limit(queryLimit).get()
                    : await collectionReference
                          .where("companyId", "==", companyFilter.id)
                          .where("reportStatus", "in", statusFilter)
                          .orderBy("reportedAt", "desc")
                          .limit(queryLimit)
                          .get();
            } else {
                collections = !companyFilter
                    ? await collectionReference.orderBy("reportedAt", "desc").limit(queryLimit).get()
                    : await collectionReference.where("companyId", "==", companyFilter.id).orderBy("reportedAt", "desc").limit(queryLimit).get();
            }

            const reviewsCache = [];
            if (collections.empty) {
                // todo handle no reviews
            } else {
                //console.log("get reported reviews collections", collections.size);
                for (const coll of collections.docs) {
                    const collReviews = coll.data();
                    collReviews.id = coll.id;
                    reviewsCache.push(collReviews);
                }
            }
            setLast(collections.docs[collections.size - 1]);
            setIsLoading(false);
            setReportedReviews(reviewsCache);
        }

        fetchData();
    }, [companyFilter, statusFilter]);

    const handleLoadMoreReviews = async () => {
        const handleMoreLimit = 25;
        setRequestedQueryLimit(requestedQueryLimit + handleMoreLimit);
        let collections;

        if (statusFilter.length > 0) {
            collections = !companyFilter
                ? await collectionReference
                      .orderBy("reportedAt", "desc")
                      .startAfter(last)
                      .where("reportStatus", "in", statusFilter)
                      .limit(handleMoreLimit)
                      .get()
                : await collectionReference
                      .where("companyId", "==", companyFilter.id)
                      .where("reportStatus", "in", statusFilter)
                      .orderBy("reportedAt", "desc")
                      .startAfter(last)
                      .limit(handleMoreLimit)
                      .get();
        } else {
            collections = !companyFilter
                ? await collectionReference.orderBy("reportedAt", "desc").startAfter(last).limit(handleMoreLimit).get()
                : await collectionReference
                      .where("companyId", "==", companyFilter.id)
                      .orderBy("reportedAt", "desc")
                      .startAfter(last)
                      .limit(handleMoreLimit)
                      .get();
        }

        const reviewsCache = [];
        //console.log("get reported reviews collections", collections.size);
        for (const coll of collections.docs) {
            const collReviews = coll.data();
            //console.log(collReviews);
            collReviews.id = coll.id;
            reviewsCache.push(collReviews);
        }
        setLast(collections.docs[collections.size - 1]);
        setReportedReviews((prevState) => prevState.concat(reviewsCache));
    };

    return (
        <Box p={2} pb={4}>
            <Box pt={2} px={2} display={"flex"} justifyContent={"flex-start"} alignItems={"flex-end"}>
                <CompanyFilter companyFilter={companyFilter} setCompanyFilter={setCompanyFilter} />
                <StatusFilter setStatusFilter={setStatusFilter} statusFilter={statusFilter} />
            </Box>
            <Divider sx={{ mb: 2 }} />
            <StatusColorDescription />
            {isLoading && (
                <Box mt={5} textAlign={"center"}>
                    Bewertungen werden geladen <CircularProgress />
                </Box>
            )}
            {!isLoading && (
                <Grid container spacing={2} mt={1}>
                    {reportedReviews.length > 0 && reportedReviews.map((review) => <ReportedGoogleReviewCard review={review} key={review.id} />)}
                </Grid>
            )}
            {!isLoading && reportedReviews.length >= requestedQueryLimit && (
                <Box py={2} textAlign={"center"}>
                    <Button onClick={() => handleLoadMoreReviews()} variant={"outlined"}>
                        Mehr gemeldete Bewertungen laden
                    </Button>
                </Box>
            )}
            {reportedReviews.length === 0 && !isLoading && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4, width: "100%" }}>
                    <Alert severity="warning">
                        <AlertTitle> Keine gemeldeten Bewertungen für dieses Unternehmen oder mit diesem Filter.</AlertTitle>
                    </Alert>
                </Box>
            )}
        </Box>
    );
}

export default ReportedReviews;

export function ReportedGoogleReviewCard({ review }) {
    const [foo, setFoo] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [copied, setCopied] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    return (
        <Grid item xs={12} lg={6} xl={4}>
            <Card
                sx={{
                    height: "100%",
                    borderTop: getBorderColor(review.reportStatus),
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <>
                        <Typography variant={"body2"} pl={2} py={1}>
                            Von <strong>{review?.companyName}</strong> <i>{review.automatic ? "automatisch" : "manuell"}</i> gemeldet am{" "}
                            <strong>{new Date(review.reportedAt).toLocaleString("de-DE", { dateStyle: "long" })}</strong>
                        </Typography>
                        <Divider />
                    </>
                    <CardHeader
                        sx={{ pb: 1 }}
                        avatar={<Avatar src={review?.reviewer_avatar} aria-label="avatar" />}
                        action={
                            review.reportStatus !== "success" &&
                            review.reportStatus !== "refused" && <MoreAction review={review} setFoo={setFoo} foo={foo} />
                        }
                        title={review?.reviewer}
                        subheader={new Date(review.datetime).toLocaleString("de-DE", { dateStyle: "long", timeStyle: "short" }) + " Uhr"}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Rating name="read-only" value={review?.rating ? parseInt(review?.rating) : 0} readOnly sx={{ pb: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                            {review?.text.length < 250 || showAll ? review?.text : review?.text.slice(0, 250) + " ..."}
                            {review?.text.length > 250 && !showAll && (
                                <Typography
                                    sx={{ cursor: "pointer" }}
                                    pl={1}
                                    color={"primary"}
                                    component={"span"}
                                    variant={"subtitle2"}
                                    onClick={() => setShowAll(true)}
                                >
                                    Alles anzeigen
                                </Typography>
                            )}
                        </Typography>
                    </CardContent>
                </Box>
                <Box>
                    {openNotes && (
                        <Box p={2}>
                            <Typography variant={"body1"}>Notizen vom Melder</Typography>
                            <Divider />
                            <Typography variant={"body2"}>{review.notes}</Typography>
                        </Box>
                    )}
                    <CardActions sx={{ justifyContent: "space-between", backgroundColor: "#f2f2f2" }}>
                        {review.notes && (
                            <Button sx={{ color: "#484848", mr: 2 }} endIcon={<EditNote color={"error"} />} onClick={() => setOpenNotes(!openNotes)}>
                                {openNotes ? "Notizen schließen" : "Notizen anzeigen"}
                            </Button>
                        )}
                        <Button
                            sx={{ color: "#484848", mr: 2 }}
                            disabled={copied}
                            endIcon={copied ? <Check /> : <CopyAll color={"error"} />}
                            onClick={() => {
                                navigator.clipboard.writeText(review.url);
                                setCopied(true);
                            }}
                        >
                            {!copied ? "Bewertungslink kopieren" : "Kopiert!"}
                        </Button>
                        <Button sx={{ color: "#484848", mr: 2 }} endIcon={<OpenInNewIcon color={"error"} />} href={review.url} target={"_blank"}>
                            Zur Bewertung
                        </Button>
                    </CardActions>
                </Box>
            </Card>
        </Grid>
    );
}

function MoreAction({ review, setFoo, foo }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleChangeStatus = (status) => {
        setIsLoading(true);
        const updateReportedReviewStatus = firebaseFunctions.httpsCallable("updateReportedReviewStatus");
        updateReportedReviewStatus({ ...review, status })
            .then((res) => {
                console.log(res);
                review.reportStatus = status;
                setFoo(!foo);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Box>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box>
                    {review.reportStatus === "reported" && (
                        <MenuItem disabled={isLoading} onClick={() => handleChangeStatus("inProgress")}>
                            Auf "In Bearbeitung" setzen
                        </MenuItem>
                    )}
                    {(review.reportStatus === "process" || review.reportStatus === "inProgress") && (
                        <MenuItem disabled={isLoading} onClick={() => handleChangeStatus("success")}>
                            Auf "Erfolgreich gelöscht" setzen
                        </MenuItem>
                    )}
                    {(review.reportStatus === "process" || review.reportStatus === "inProgress") && (
                        <MenuItem disabled={isLoading} onClick={() => handleChangeStatus("refused")}>
                            Auf "Löschung verweigert" setzen
                        </MenuItem>
                    )}
                    {isLoading && <SaveCircularProgress />}
                </Box>
            </Popover>
        </Box>
    );
}
