import { defaultTheme } from "react-admin";

export const theme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "unset",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "unset",
                    fontSize: "1rem",
                },
            },
        },
    },
    palette: {
        ...defaultTheme.palette,
        primary: { main: "#dd262b" },
    },
    /*typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },*/
};
