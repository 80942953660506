import Card from "@mui/material/Card";
import { Box, Button, CardActions, CardHeader, Divider, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import DoneIcon from "@mui/icons-material/Done";
import parse from "html-react-parser";

function Notification({ id, title, text, createdAt, handleSetRead }) {
    return (
        <Card
            sx={{
                m: 1,
            }}
        >
            <CardHeader
                sx={{ pb: 0 }}
                title={<Typography variant={"body1"}>{title}</Typography>}
                subheader={
                    <Typography variant={"body2"} color={"grey"}>
                        {new Date(createdAt).toLocaleString("de-DE", { dateStyle: "long", timeStyle: "short" }) + " Uhr"}
                    </Typography>
                }
            />
            <CardContent sx={{ pt: 0 }}>
                <Divider sx={{ mb: 1 }} />
                <Box>{parse(text)}</Box>
            </CardContent>
            <Box>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end", backgroundColor: "#f2f2f2" }}>
                    <Button endIcon={<DoneIcon />} color={"secondary"} onClick={() => handleSetRead(id)}>
                        Als gelesen markieren
                    </Button>
                </CardActions>
            </Box>
        </Card>
    );
}

export default Notification;
