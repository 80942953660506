import { FormDataConsumer, useEditContext, usePermissions } from "react-admin";
import { Alert, Button, Divider, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import QRCode from "react-qr-code";
import { useState } from "react";
import { useCompanyContext } from "../../context/CompanyContext";

function ReferralCode() {
    const [copied, setCopied] = useState(false);
    const { permissions } = usePermissions();
    const { companies } = useCompanyContext();

    const getCompany = (id) => {
        const company = companies.find((com) => com.id == id);
        if (company) return `${company.name}, ${company.address} ${company.zipCode} ${company.city}`;
        return "";
    };

    return (
        <FormDataConsumer>
            {({ formData }) => (
                <>
                    {permissions.role === "admin" && formData.recommendedBy && (
                        <Alert sx={{ mb: 2 }}>
                            <Typography>
                                Kunde wurde geworben durch <strong>{getCompany(formData.recommendedBy)}</strong>
                            </Typography>
                        </Alert>
                    )}
                    <Alert severity="info" sx={{ mb: 2 }}>
                        <Typography>Den Empfehlungslink oder QR teilen um Kunden zu werben.</Typography>
                    </Alert>
                    {formData.id && (
                        <>
                            <Typography variant={"h5"} mb={1}>
                                Ihr Empfehlungslink
                            </Typography>
                            <Divider sx={{ width: "100%" }} />
                            <Typography variant={"subtitle1"}>{`https://starprotection.de/login?register=y&referralCode=${formData.id}`}</Typography>
                            <Button
                                variant={"contained"}
                                sx={{ mb: 2 }}
                                disabled={copied}
                                endIcon={copied && <Check />}
                                onClick={() => {
                                    navigator.clipboard.writeText(`https://starprotection.de/login?register=y&referralCode=${formData.id}`);
                                    setCopied(true);
                                }}
                            >
                                {!copied ? "Empfehlungslink kopieren" : "Kopiert!"}
                            </Button>
                            <Typography variant={"h5"} mb={1}>
                                Ihr Empfehlungslink als QR-Code
                            </Typography>
                            <Divider sx={{ width: "100%", mb: 1 }} />
                            <QRCode value={`https://starprotection.de/login?register=y&referralCode=${formData.id}`} />
                        </>
                    )}
                </>
            )}
        </FormDataConsumer>
    );
}

export default ReferralCode;
