import { Accordion, Typography } from "@mui/material";
import { BooleanInput, NumberInput, TextInput } from "react-admin";
import GoogleLinkExplain from "../GoogleLinkExplain";
import { useState } from "react";
import { parseGoogleLink } from "../Create";
import CustomAccordion from "./CustomAccordion";

function Google() {
    const [googleLinkError, setGoogleLinkError] = useState(false);
    const parseGoogleLinkWrapper = (value) => {
        setGoogleLinkError(false);
        //https://www.google.de/maps/place/DAA+Weiden+Fitnesstudio+Die+Abnehm+Akademie/@49.6923834,12.1340813,17z/data=!3m1!4b1!4m6!3m5!1s0x47a03868564209bb:0xc883f46991760170!8m2!3d49.69238!4d12.13627!16s%2Fg%2F1tf6911f
        if (value.length > 0 && (value.includes("https://www.google.com/maps/place/") || value.includes("https://www.google.de/maps/place/"))) {
            return parseGoogleLink(value);
        } else if (value.length === 0) {
            setGoogleLinkError(false);
            return value;
        } else {
            setGoogleLinkError(true);
            return value;
        }
    };
    return (
        <CustomAccordion label={"Google Bewertungen"} source={"google"} description={"Rezensionen und Bewertungen über Ihr Unternehmen"}>
            <TextInput
                defaultValue={""}
                label="Google Link"
                source="apis.google.link"
                helperText={false}
                parse={parseGoogleLinkWrapper}
                sx={{ width: "100% !important" }}
            />
            {googleLinkError && (
                <Typography color={"error"} sx={{ mb: 1 }} fontWeight={"bold"}>
                    Dieser Google Link ist nicht valide. Das richtige Format ist: "https://www.google.com/maps/place/..."
                </Typography>
            )}
            <GoogleLinkExplain />
            <BooleanInput label="Automatische Weiterleitung an Anwalt" source="apis.google.automaticForwarding" helperText={false} />
            <NumberInput
                min={1}
                max={100}
                label="Automatische Weiterleitungen Limit pro Monat"
                source="apis.google.automaticForwardingLimit"
                helperText={"Z.b. Bei 5, werden monatlich maximal 5 Bewertungen weitergeleitet. Weiterleitung erfolgt einmal täglich im Hintergrund"}
            />
            <NumberInput
                min={2}
                max={5}
                label="Automatische Weiterleitungen unter Bewertung"
                source="apis.google.automaticForwardingThreshold"
                helperText={"Zum Beispiel: 3 für Alle Bewertungen mit 2 und 1 Sternen"}
            />
        </CustomAccordion>
    );
}

export default Google;
