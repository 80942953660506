import {
    ArrayInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    FormTab,
    NumberInput,
    required,
    SaveButton,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    Toolbar,
    usePermissions,
} from "react-admin";
import { Alert, Button, Divider, Typography } from "@mui/material";
import { Add, Check, Delete } from "@mui/icons-material";
import { disableAddCompetitors, parseGoogleLink } from "./Create";
import GoogleLinkExplain from "./GoogleLinkExplain";
import { useCompanyContext } from "../../context/CompanyContext";
import { useState } from "react";
import ReferralCode from "./ReferralCode";
import PermanentInvoice from "./PermanentInvoice";
import Portals from "./Portals/Portals";
import Competitors from "./Competitors";

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton disabled={props.googleLinkError} label={"Kunde speichern"} />
    </Toolbar>
);
function CustomerEdit() {
    const { permissions } = usePermissions();
    const isAdmin = permissions.role == "admin";
    const { companies } = useCompanyContext();
    const [googleLinkError, setGoogleLinkError] = useState(false);
    const parseGoogleLinkWrapper = (value) => {
        setGoogleLinkError(false);
        //https://www.google.de/maps/place/DAA+Weiden+Fitnesstudio+Die+Abnehm+Akademie/@49.6923834,12.1340813,17z/data=!3m1!4b1!4m6!3m5!1s0x47a03868564209bb:0xc883f46991760170!8m2!3d49.69238!4d12.13627!16s%2Fg%2F1tf6911f
        if (value.length > 0 && (value.includes("https://www.google.com/maps/place/") || value.includes("https://www.google.de/maps/place/"))) {
            return parseGoogleLink(value);
        } else if (value.length === 0) {
            setGoogleLinkError(false);
            return value;
        } else {
            setGoogleLinkError(true);
            return value;
        }
    };

    return (
        <Edit redirect={isAdmin ? "list" : false}>
            <TabbedForm toolbar={<CustomToolbar googleLinkError={googleLinkError} />}>
                <FormTab label="Kunde" name="company">
                    {permissions.role == "user" && companies?.length > 1 && (
                        <Alert severity={"info"}>
                            <strong>Hinweis:</strong> Um ein anderen Kunden zu bearbeiten, wählen Sie diesen in der Navigationsleiste aus und klicken
                            Sie erneut auf "Kundendaten" im Menü links.
                        </Alert>
                    )}
                    {isAdmin && <BooleanInput label="Status (Inaktiv / Aktiv)" source="active" />}
                    {isAdmin && <TextInput disabled label="Id" source="id" helperText={false} />}
                    <TextInput label="Kundenname" source="name" required defaultValue={"Mustermann Gmbh"} helperText={false} />
                    <TextInput label="Straße & Hausnummer" source="address" helperText={false} />
                    <TextInput label="Postleitzahl" source="zipCode" helperText={false} />
                    <TextInput label="Stadt" source="city" helperText={false} />
                    <TextInput label="E-Mail des Kunden" source="email" type={"email"} helperText={false} />
                    <TextInput label="Telefonnummer des Kunden" source="phoneNumber" helperText={false} />
                    <TextInput label="Webseite" source="website" helperText={false} />
                    <TextInput label="Name der Kontaktperson" source="contactName" helperText={false} />
                </FormTab>
                <FormTab label="Zahlungsdaten" name="payment">
                    <TextInput label="Kontoinhaber" source="billingInfos.accountHolder" helperText={false} />
                    <TextInput label="IBAN" source="billingInfos.iban" helperText={false} />
                    <TextInput label="BIC" source="billingInfos.bic" helperText={false} />
                    <TextInput label="Bankname" source="billingInfos.financeInstitute" helperText={false} />
                </FormTab>
                <FormTab label="Portale" name="apis">
                    <Portals />
                </FormTab>
                <FormTab label="Mitbewerber" name="competitors">
                    <Competitors />
                </FormTab>
                <FormTab label="Kunden werben" name="referral">
                    <ReferralCode />
                </FormTab>
                <FormTab label="Dauerrechnung" name="permanent-invoice">
                    <PermanentInvoice />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

export default CustomerEdit;
