import {
    AutocompleteArrayInput,
    BooleanInput,
    Edit,
    email,
    minLength,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    usePermissions,
    useRedirect,
} from "react-admin";
import { useCompanyContext } from "../../context/CompanyContext";
import { Divider, Typography } from "@mui/material";
import { firebaseFunctions } from "../../App";

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label={"Benutzer speichern"} />
    </Toolbar>
);
function UserEdit() {
    const { permissions } = usePermissions();
    const isAdmin = permissions.role === "admin";
    const { companies } = useCompanyContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const optionRenderer = (choice) =>
        `${choice.name}${choice?.zipCode || choice?.city ? "," : ""} ${choice?.zipCode ? choice?.zipCode : ""}  ${choice?.city ? choice?.city : ""}`;
    const requiredValidate = required("Dieser Wert ist notwendig");
    const updateUser = firebaseFunctions.httpsCallable("updateUserCall");

    const handleSubmit = (data) => {
        updateUser(data)
            .then((result) => {
                console.log(result);
                if (isAdmin) redirect("/users");
                notify("Benutzer geupdated", { type: "success", autoHideDuration: 5000 });
            })
            .catch((err) => {
                console.log(err);
                notify(err.message, { type: "error" });
            });
    };

    return (
        <Edit>
            <SimpleForm toolbar={<CustomToolbar />} onSubmit={handleSubmit}>
                <TextInput
                    source={"email"}
                    label={"E-Mail"}
                    validate={[requiredValidate, email("Dieser Wert muss eine gültige E-Mail Adresse sein")]}
                />
                <TextInput source={"firstName"} label={"Vorname"} validate={[requiredValidate]} />
                <TextInput source={"lastName"} label={"Nachname"} validate={[requiredValidate]} />
                <BooleanInput
                    sx={{ marginBottom: 1 }}
                    label="2-Faktor-Login"
                    source="twoFactorAuth"
                    helperText={
                        "Wenn der 2-Faktor-Login aktiviert ist, wird ein 6-stelliger Code auf die hinterlegte E-Mail Adresse gesendet. Dieser muss dann im zweiten Login-Schritt eingegeben werden."
                    }
                />
                {isAdmin && (
                    <SelectInput
                        source="role"
                        label={"Rolle"}
                        validate={[requiredValidate]}
                        choices={[
                            { id: "user", name: "Benutzer" },
                            { id: "admin", name: "Admin" },
                        ]}
                    />
                )}
                {isAdmin && (
                    <AutocompleteArrayInput
                        helperText={"Einem Admin müssen keine Unternehmen zugeordnet werden."}
                        source="companies"
                        label={"Zugeordnete Unternehmen"}
                        choices={companies}
                        optionText={optionRenderer}
                    />
                )}
                {isAdmin && <Divider sx={{ my: 2, width: "100%" }} />}
                <Typography variant={"subtitle1"}>Neues Passwort vergeben</Typography>
                <TextInput
                    source={"password"}
                    label={"Passwort"}
                    validate={[minLength(8)]}
                    helperText={
                        isAdmin ? (
                            <>
                                <strong>Wichtig!</strong> Mindestens 8 Zeichen. Das gesetze Passwort müssen Sie dem Benutzer gesondert mitteilen. Dies
                                geschieht <i>nicht</i> nur das System.
                            </>
                        ) : (
                            "Mindestens 8 Zeichen."
                        )
                    }
                />
            </SimpleForm>
        </Edit>
    );
}

export default UserEdit;
