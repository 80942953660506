import {
    BooleanField,
    CreateBase,
    Datagrid,
    List,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
    SaveButton,
    Toolbar,
    useNotify,
    useRedirect,
    useRefresh,
    TextField,
    FormDataConsumer,
} from "react-admin";
import { Alert, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { firestore } from "../../App";

const CreateToolbar = (props) => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();
    const { reset, resetField } = useFormContext();
    return (
        <Toolbar {...props}>
            <SaveButton
                label={"Portal hinzufügen"}
                mutationOptions={{
                    onSuccess: (data) => {
                        notify("Neues Portal hinzugefügt!", {
                            type: "success",
                        });
                        redirect(false);
                        reset();
                        resetField("id");
                        setTimeout(() => refresh(), 1000);
                    },
                }}
                type="button"
            />
        </Toolbar>
    );
};

const IDField = () => {
    const [portals, setPortals] = useState([]);
    const { watch, setValue, getFieldState } = useFormContext();
    const watchName = watch("name");
    const idFieldState = getFieldState("id");

    const validateID = (value) => {
        for (const portal of portals) {
            if (portal.id === value) {
                return "Diese ID ist bereits vergeben. Namen anpassen!";
            }
        }
        return undefined;
    };

    useEffect(() => {
        loadPortals();
    }, []);

    useEffect(() => {
        const newId = watchName == null ? "" : watchName.toLowerCase().replaceAll(" ", "_");
        if (newId) {
            setValue("id", newId, { shouldValidate: true, shouldTouch: true });
        }
    }, [watchName]);

    const loadPortals = async () => {
        const portalCache = [];
        const querySnapshot = await firestore.collection("monitoredPortals").get();
        querySnapshot.forEach((doc) => {
            const portal = doc.data();
            portal.id = doc.id;
            portalCache.push(portal);
        });
        setPortals(portalCache);
    };

    return (
        <TextInput
            source={"id"}
            label="Eindeutige ID (generiert)"
            validate={[required("Erforderlich"), validateID]}
            disabled
            helperText={idFieldState.invalid}
        />
    );
};
const Aside = () => {
    return (
        <CreateBase>
            <Card sx={{ width: 500, margin: "3em 1em", p: 1, "& .MuiTextField-root": { width: "100% !important" } }}>
                <SimpleForm toolbar={<CreateToolbar />} mode={"onChange"}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Neues Portal hinzufügen
                    </Typography>
                    <IDField />
                    <TextInput source={"name"} label={"Name"} helperText={false} validate={required("Erforderlich")} />
                    <TextInput
                        source="description"
                        label={"Beschreibung"}
                        helperText={"Beschreibung um welche Art von Bewertungen es sich handelt."}
                        validate={required("Erforderlich")}
                    />
                    <BooleanInput source={"api"} label={"Automatische Überwachung"} helperText={false} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <Alert severity={"warning"} sx={{ display: formData.api ? "flex" : "none", mb: 1 }}>
                                API Implementierung erforderlich!
                            </Alert>
                        )}
                    </FormDataConsumer>
                </SimpleForm>
            </Card>
        </CreateBase>
    );
};

function MonitoredPortalsList() {
    return (
        <List aside={<Aside />} exporter={false} perPage={50} pagination={false}>
            <Alert severity={"info"}>
                Kunden können bei der Registrierung und in Ihrem Konto auswählen, welches dieser Portale überwacht werden soll.
            </Alert>
            <Datagrid bulkActionButtons={false} hover={false}>
                <TextField source="id" label={"ID"} />
                <TextField source="name" />
                <TextField source="description" label={"Beschreibung"} />
                <BooleanField source="api" label={"Automatische Überwachung"} />
            </Datagrid>
        </List>
    );
}

export default MonitoredPortalsList;
