import { Box, Button, CircularProgress, styled, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { storage } from "../../App";
import { useNotify, usePermissions, useRecordContext, useRefresh } from "react-admin";
import { Download } from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

function PermanentInvoice() {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [fileError, setFileError] = useState("");
    const [fileUploadingError, setFileUploadingError] = useState("");
    const [uploadingFile, setUploadingFile] = useState(false);
    const { permissions } = usePermissions();
    const storageRef = storage.ref();

    const handleSelectFile = (event) => {
        setIsFilePicked(false);
        setSelectedFile(null);
        setFileError("");
        console.log(event.target.files);
        if (event.target.files[0].type !== "application/pdf") {
            setFileError("Bitte ein PDF auswählen");
        } else if (event.target.files[0].size > 5242880) {
            setFileError("Datei zu groß. Maximal 5MB");
        } else {
            if (event.target.files[0]) {
                setSelectedFile(event.target.files[0]);
                setIsFilePicked(true);
            }
        }
    };

    const handleUploadFile = () => {
        setUploadingFile(true);
        setFileUploadingError("");
        let metadata = {
            contentType: "application/pdf",
            customMetadata: {
                // Ignore because custom medata is needed as string
                // See https://firebase.google.com/docs/storage/web/file-metadata#custom_metadata
                // prettier-ignore
                "customerId": record["id"],
            },
        };
        storageRef
            .child(`permanent-invoices/${record["id"]}/invoice.pdf`)
            .put(selectedFile, metadata)
            .then((snapshot) => {
                //console.log("Uploaded", snapshot.totalBytes, "bytes.");
                console.log("File metadata:", snapshot.metadata);
                snapshot.ref.getDownloadURL().then((url) => {
                    console.log("File available at", url);
                    //setDownloadLink(url);
                });
                notify("Dauerrechnung erfolgreich hochgeladen!", { type: "success" });
                setSelectedFile(null);
                setTimeout(() => refresh(), 1000);
            })
            .catch((error) => {
                setFileUploadingError(error);
                notify(error.toString(), { type: "error" });
            })
            .finally(() => setUploadingFile(false));
    };

    return (
        <Box>
            {permissions.role === "admin" && (
                <>
                    <Typography variant={"body1"}>
                        Hier kann eine Dauerrechnung hochgeladen werden. Das Unternehmen wird danach per E-Mail informiert. (Nur für Admins sichtbar)
                    </Typography>
                    <Box mb={2}>
                        <Box sx={{ border: "1px grey dashed", p: 2, mt: 2, width: "fit-content" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Button component="label" variant="outlined" startIcon={<AttachFileIcon />} sx={{ mr: 1 }}>
                                    Datei auswählen
                                    <VisuallyHiddenInput type="file" accept={".pdf"} onChange={handleSelectFile} />
                                </Button>
                                {selectedFile && isFilePicked ? (
                                    <Typography variant={"body2"}>
                                        {selectedFile.name} ({(selectedFile.size / (1024 * 1024)).toFixed(2)} MB)
                                    </Typography>
                                ) : (
                                    <Typography variant={"body2"}>Keine Datei ausgewählt</Typography>
                                )}
                                {fileError.length > 0 && (
                                    <Typography variant={"body1"} color={"error"}>
                                        &nbsp;{fileError}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Typography variant={"subtitle2"} sx={{ mb: 2, mt: 0.5 }}>
                            Datei muss im PDF Format sein. Maximale größe 5MB.
                        </Typography>
                        <Button
                            variant={"contained"}
                            onClick={handleUploadFile}
                            disabled={uploadingFile || fileError.length > 0 || !selectedFile}
                            startIcon={<CloudUploadIcon />}
                        >
                            {uploadingFile && <CircularProgress sx={{ alignSelf: "center", mr: 1, color: "white" }} size={20} />}
                            Dauerrechnung jetzt hochladen
                        </Button>
                        {fileUploadingError.length > 0 && (
                            <Typography variant={"body1"} color={"error"}>
                                {fileUploadingError}
                            </Typography>
                        )}
                    </Box>
                </>
            )}
            {record["invoiceDownloadUrl"] ? (
                <>
                    <Typography variant={"h5"} sx={{ mb: 2 }}>
                        Ihre Dauerrechnung herunterladen
                    </Typography>
                    <Button startIcon={<Download />} variant={"contained"} target={"_blank"} href={record["invoiceDownloadUrl"]}>
                        Herunterladen
                    </Button>
                </>
            ) : (
                <Typography variant={"body1"}>Es ist noch keine Dauerrechnung hinterlegt</Typography>
            )}
        </Box>
    );
}

export default PermanentInvoice;
