import { Alert, AlertTitle, Box } from "@mui/material";

function CustomerNotActiveAlert() {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Alert severity="warning">
                <AlertTitle> Dieser Kunde ist nicht aktiv. </AlertTitle>
                Aktivieren Sie diesen Kunden, um diese Daten zu sehen. (Ggf. stehen die Daten erst am Folgetag zur Verfügung)
            </Alert>
        </Box>
    );
}

export default CustomerNotActiveAlert;
