import { Menu, usePermissions } from "react-admin";
import { Icon } from "@mui/material";
import { useCompanyContext } from "../context/CompanyContext";
import { FcConferenceCall, FcSettings, FcShop, FcGoogle, FcBusinessman, FcTemplate } from "react-icons/fc";

export const GIcon = () => (
    <Icon sx={{ lineHeight: "1" }}>
        <img alt="icon" src="/google_icon.svg" />
    </Icon>
);

export const SPIcon = () => (
    <Icon sx={{ lineHeight: "1" }}>
        <img style={{ height: "inherit" }} alt="icon" src="/logo_einzeln.png" />
    </Icon>
);
const CustomMenu = (props) => {
    const { permissions } = usePermissions();
    const { selectedCompany } = useCompanyContext();
    return (
        <Menu
            {...props}
            sx={{
                "& > a": { py: 2 },
                "& > a.RaMenuItemLink-active": { backgroundColor: "#ebebeb" },
                "& > a.RaMenuItemLink-active svg.MuiSvgIcon-root ": { color: "#dd282b" },
            }}
        >
            <Menu.DashboardItem />
            {permissions.role === "admin" && <Menu.Item to="/admin/reviews" primaryText="Gemeldete Bewertungen" leftIcon={<SPIcon />} />}
            <Menu.Item to={"/google-reviews"} primaryText="Google Bewertungen" leftIcon={<FcGoogle size={"1.6em"} />} />
            <Menu.Item to={"/competitors"} primaryText="Mitbewerber" leftIcon={<FcConferenceCall size={"1.6em"} />} />
            {permissions.role === "admin" && <Menu.Item to="/companies" primaryText="Kunden" leftIcon={<FcShop size={"1.6em"} />} />}
            {permissions.role === "admin" && <Menu.Item to="/users" primaryText="Benutzer" leftIcon={<FcBusinessman size={"1.6em"} />} />}
            {permissions.role === "admin" && <Menu.Item to="/monitoredPortals" primaryText="Portale" leftIcon={<FcTemplate size={"1.6em"} />} />}
            {permissions.role === "user" && (
                <Menu.Item to={`/companies/${selectedCompany?.id}`} primaryText="Kundendaten" leftIcon={<FcShop size={"1.6em"} />} />
            )}
            <Menu.Item to={`/users/${permissions?.user_id}`} primaryText="Meine Daten" leftIcon={<FcSettings size={"1.6em"} />} />
        </Menu>
    );
};

export default CustomMenu;
