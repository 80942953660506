import { useEffect, useState } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoginData from "./LoginData";
import CompanyData from "./CompanyData";
import PaymentData from "./PaymentData";
import Agreement from "./Agreement";
import { People } from "@mui/icons-material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PaymentIcon from "@mui/icons-material/Payment";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { useSearchParams } from "react-router-dom";
import Portals from "./Portals";
import { firestore } from "../../../App";

function RegisterStepper({ setRegister, referralCodeActive }) {
    const [activeStep, setActiveStep] = useState(0);
    const [formState, setFormState] = useState({ user: { email: "", password: "", passwordRepeat: "" } });
    let [searchParams] = useSearchParams();

    const [portals, setPortals] = useState([]);

    useEffect(() => {
        loadPortals();
    }, []);

    const loadPortals = async () => {
        const portalCache = [];
        const querySnapshot = await firestore.collection("monitoredPortals").get();
        querySnapshot.forEach((doc) => {
            const portal = doc.data();
            portal.id = doc.id;
            portalCache.push(portal);
        });
        portalCache.sort((a, b) => new Date(a.createdate.seconds * 1000) - new Date(b.createdate.seconds * 1000));
        setPortals(portalCache);
    };

    useEffect(() => {
        if (referralCodeActive) {
            const referralCode = searchParams.get("referralCode");
            setFormState((prev) => ({ ...prev, recommendedBy: referralCode }));
        }
    }, [referralCodeActive]);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [
        {
            label: "Zugangsdaten",
            description: "Eingabe der gewünschten Zugangsdaten",
            component: <LoginData handleNext={handleNext} handleBack={handleBack} formState={formState} setFormState={setFormState} />,
            labelIcon: <People />,
        },
        {
            label: "Unternehmensdaten",
            description: "Eingabe der Kontaktdaten des Unternehmens",
            component: <CompanyData handleNext={handleNext} handleBack={handleBack} formState={formState} setFormState={setFormState} />,
            labelIcon: <ApartmentIcon />,
        },
        {
            label: "Portale",
            description: "Auswahl der zu überwachenden Portale",
            component: (
                <Portals handleNext={handleNext} handleBack={handleBack} formState={formState} setFormState={setFormState} portals={portals} />
            ),
            labelIcon: <PaymentIcon />,
        },
        {
            label: "Zahlungsdaten",
            description: "Eingabe der Zahlungsdaten für den Vertrag",
            component: <PaymentData handleNext={handleNext} handleBack={handleBack} formState={formState} setFormState={setFormState} />,
            labelIcon: <PaymentIcon />,
        },
        {
            label: "Vereinbarung",
            description: "Zustimmung der Vereinbarungen und Bestellung",
            component: (
                <Agreement
                    handleNext={handleNext}
                    handleBack={handleBack}
                    formState={formState}
                    setFormState={setFormState}
                    setRegister={setRegister}
                />
            ),
            labelIcon: <HandshakeIcon />,
        },
    ];

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper alternativeLabel={true} activeStep={activeStep} sx={{ my: 5 }}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        {/*<StepLabel StepIconComponent={() => CustomStepIcon({ icon: step.labelIcon })}>{step.label}</StepLabel>*/}
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
                <Typography fontWeight={"bold"} sx={{ borderBottom: "1px solid #bdbdbd", mb: 2, color: "#3f3f3f" }}>
                    {steps[activeStep].description}
                </Typography>
                {steps[activeStep].component}
            </Box>
        </Box>
    );
}

export default RegisterStepper;
