import { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../App";
import { useUserContext } from "./UserContext";

const CompanyContext = createContext({});

export default function CompanyProvider({ children }) {
    const { user } = useUserContext();
    // 1. User loggt sich ein
    // 2. CompanyIds werden gelesen
    // 3. CompanyIds in companyIds speichern
    // 4. companies über companyIds holen.

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        if (Object.keys(user).length > 0) loadCompanies();
    }, [user]);

    const loadCompanies = async () => {
        let loadedCompanies = [];
        if (user.role === "admin") {
            // todo check if nobody can edit this
            const querySnapshot = await firestore.collection("companies").get();
            querySnapshot.forEach((doc) => {
                const company = doc.data();
                company.id = doc.id;
                loadedCompanies.push(company);
            });
        } else {
            for (const company of user.companies) {
                const doc = await firestore.collection("companies").doc(company).get();
                if (!doc.exists) {
                    console.log("No such document!");
                } else {
                    console.log("Document data:", doc.data());
                    const company = doc.data();
                    company.id = doc.id;
                    loadedCompanies.push(company);
                }
            }
        }
        try {
            loadedCompanies = loadedCompanies.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        } catch (e) {
            console.log(e);
        }
        setCompanies(loadedCompanies);
        setSelectedCompany(loadedCompanies[0]);
    };

    const setSelectedCompanyViaId = (id) => {
        const foundCompany = companies.find((company) => company.id === id);
        setSelectedCompany(foundCompany);
    };

    return (
        <CompanyContext.Provider value={{ companies, selectedCompany, setSelectedCompanyViaId, loadCompanies }}>{children}</CompanyContext.Provider>
    );
}

export const useCompanyContext = () => useContext(CompanyContext);
