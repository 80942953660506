import { Autocomplete, Button, TextField } from "@mui/material";
import { useCompanyContext } from "../../context/CompanyContext";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
function CompanyFilter({ companyFilter, setCompanyFilter }) {
    const { companies } = useCompanyContext();
    const [inputValue, setInputValue] = useState("");

    const options = companies.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
        };
    });

    const handleChange = (event, company) => {
        setCompanyFilter(company);
    };

    if (companies) {
        return (
            <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.name}
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        key={option.id}
                        style={{
                            borderLeftStyle: "solid",
                            borderLeftWidth: 4,
                            borderLeftColor: option.active ? "green" : "red",
                            paddingLeft: "5px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            marginTop: "4px",
                            marginBottom: "4px",
                            marginLeft: "30px",
                        }}
                    >
                        {option.name}
                    </li>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                groupBy={(option) => option.firstLetter}
                noOptionsText={"Kein Unternehmen gefunden"}
                sx={{ m: 1, width: "300px !important", "& .MuiInputBase-root": { borderBottom: companyFilter && "2px solid red" } }}
                renderInput={(params) => <TextField {...params} label="Unternehmen filtern" />}
                value={companyFilter}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
            />
        );
    }
}

export default CompanyFilter;
