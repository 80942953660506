import CustomAccordion from "./CustomAccordion";
import { Alert, Typography } from "@mui/material";
import { TextInput } from "react-admin";

function ManualPortal({ label, id, description }) {
    return (
        <CustomAccordion label={label} source={id} description={description}>
            <Alert severity="info">
                <Typography>Dieses Portal wird händisch überwacht.</Typography>
            </Alert>
            <TextInput
                source={`apis.${id}.link`}
                label={label + " Link"}
                helperText={"Link zu Ihren Unternehmen in diesem Portal"}
                defaultValue={""}
                sx={{ width: "100% !important" }}
            />
        </CustomAccordion>
    );
}

export default ManualPortal;
