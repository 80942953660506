import * as React from "react";
import { Box, Button, Container } from "@mui/material";
import { useEffect, useState } from "react";
import Login from "./Login";
import Register from "./Register/Register";
import { useSearchParams } from "react-router-dom";

const LoginPage = () => {
    const [register, setRegister] = useState(false);
    const [referralCodeActive, setReferralCodeActive] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("register")) setRegister(true);
        if (searchParams.get("referralCode")) setReferralCodeActive(true);
    }, [searchParams]);

    return (
        <Box
            sx={{
                backgroundColor: "#d32f2f",
                height: "100vh",
                maxHeight: "-webkit-fill-available", // Because of iOS Safari menu bar bug
            }}
        >
            <Box
                component={"img"}
                src={"/shield.svg"}
                sx={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    maxHeight: "100%",
                }}
            />
            <Box
                component={"img"}
                src={"/wave.svg"}
                sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    maxHeight: "100%",
                }}
            />
            <Container
                maxWidth={"md"}
                sx={{
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: { xs: "space-around", md: "center" },
                }}
            >
                {register ? <Register setRegister={setRegister} referralCodeActive={referralCodeActive} /> : <Login setRegister={setRegister} />}
            </Container>
            <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", bottom: 20, width: "100%", px: 5 }}>
                <Button href={"/agreements/datenschutzerklaerung.pdf"} target={"blank"}>
                    Datenschutzerklärung
                </Button>
                <Button href={"/agreements/impressum.pdf"} target={"blank"}>
                    Impressum
                </Button>
            </Box>
        </Box>
    );
};

export default LoginPage;
