import { Box, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

function CompanyData({ handleBack, handleNext, formState, setFormState }) {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        handleNext();
        setFormState((prev) => ({ ...prev, company: data }));
    };

    return (
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Controller
                        defaultValue={formState?.company?.name}
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.name} label={"Unternehmensname *"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.company?.website}
                        name="website"
                        control={control}
                        render={({ field }) => <TextField {...field} label={"Webseite"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.company?.contactName}
                        name="contactName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.contactName} label={"Ansprechpartner *"} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        defaultValue={formState?.company?.address}
                        name="address"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.address} label={"Straße & Hausnummer *"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.company?.zipCode}
                        name="zipCode"
                        control={control}
                        rules={{ required: true, minLength: 5, maxLength: 5, pattern: /^\d+$/ }}
                        render={({ field }) => <TextField {...field} error={!!errors?.zipCode} label={"PLZ *"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.company?.city}
                        name="city"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.city} label={"Ort *"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.company?.email}
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.email} type={"email"} label={"E-Mail des Unternehmens *"} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        defaultValue={formState?.company?.phoneNumber}
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => <TextField {...field} label={"Telefonnummer"} />}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", my: 2 }}>
                    <Button color={"inherit"} onClick={handleBack} sx={{ mr: 1 }}>
                        Zurück
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type={"submit"}>Weiter</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CompanyData;
