import Google from "./Google";
import { Alert, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { firestore } from "../../../App";
import ManualPortal from "./ManualPortal";

const getPortalAccordion = (id, name, description) => {
    switch (id) {
        case "google":
            return <Google />;
        default:
            return <ManualPortal label={name} id={id} description={description} />;
    }
};

function Portals() {
    // todo get all portals, switch case and find automatic
    const [portals, setPortals] = useState([]);

    useEffect(() => {
        loadPortals();
    }, []);

    const loadPortals = async () => {
        const portalCache = [];
        const querySnapshot = await firestore.collection("monitoredPortals").get();
        querySnapshot.forEach((doc) => {
            const portal = doc.data();
            portal.id = doc.id;
            portalCache.push(portal);
        });
        portalCache.sort((a, b) => new Date(a.createdate.seconds * 1000) - new Date(b.createdate.seconds * 1000));
        setPortals(portalCache);
    };

    return (
        <Box sx={{ width: { xs: "100%", lg: "50%" } }}>
            <Alert severity="info" sx={{ mb: 2 }}>
                <Typography>Sie können hier wählen, welche Portale überwacht werden sollen.</Typography>
            </Alert>
            {portals.length > 0 && portals.map((portal) => getPortalAccordion(portal.id, portal.name, portal.description))}
        </Box>
    );
}

export default Portals;
