import * as React from "react";
import { AppBar, useGetIdentity } from "react-admin";
import { Autocomplete, Badge, Box, Dialog, DialogTitle, IconButton, TextField } from "@mui/material";
import { useCompanyContext } from "../context/CompanyContext";
import { useEffect, useState } from "react";
import { useUserContext } from "../context/UserContext";
import MailIcon from "@mui/icons-material/Mail";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Notifications from "./Notifications/Notifications";
import { firestore } from "../App";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const CustomAppBar = (props) => {
    const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
    const { identity, isLoading } = useGetIdentity();
    const { loadUser } = useUserContext();
    const { companies } = useCompanyContext();
    const { user } = useUserContext();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        loadUser();
    }, [identity, isLoading]);

    useEffect(() => {
        if (user.id) loadNotifications();
    }, [user]);

    useEffect(() => {
        let id = 0;
        id = setInterval(() => {
            loadNotifications();
        }, 300000);
        return () => clearInterval(id);
    }, [user]);

    const loadNotifications = async () => {
        const notificationCache = [];
        const querySnapshot = await firestore
            .collection("notifications")
            .where("userId", "==", user.id)
            .where("read", "==", false)
            .orderBy("createdAt", "desc")
            .limit(200)
            .get();
        querySnapshot.forEach((doc) => {
            const notification = doc.data();
            notification.id = doc.id;
            notificationCache.push(notification);
        });
        setNotifications(notificationCache);
    };

    const removeFromNotifications = (id) => setNotifications(notifications.filter((not) => not.id !== id));

    return (
        <AppBar
            sx={{
                backgroundColor: "white",
                color: "grey",
                "& .RaAppBar-title": {
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },
            }}
            {...props}
        >
            {/*        <Typography
            variant="h6"
            color="inherit"
            id="react-admin-title"
        />*/}
            <img src={"logo.png"} alt={"starprotection_logo"} height={"35px"} />
            <Box component="span" sx={{ flex: 1 }} />
            {companies?.length > 1 && <CompanySelect />}
            <IconButton aria-label="notifications" sx={{ mx: 0.5 }} onClick={() => setOpenNotificationDialog(true)}>
                <Badge color="primary" badgeContent={notifications.length} max={99}>
                    <MailIcon />
                </Badge>
            </IconButton>
            {openNotificationDialog && (
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    onClose={() => setOpenNotificationDialog(false)}
                    open={openNotificationDialog}
                    sx={{
                        "& .MuiDialog-container": {
                            justifyContent: "flex-end",
                            "& > .MuiPaper-root": {
                                m: 0,
                                height: "100%",
                                maxHeight: "100%",
                                width: "100%",
                                backgroundColor: "#fafafb",
                            },
                        },
                    }}
                    TransitionComponent={Transition}
                >
                    <DialogTitle
                        sx={{
                            backgroundColor: "#fff",
                            boxShadow: 3,
                            height: 44,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        Benachrichtigungen
                        <IconButton onClick={() => setOpenNotificationDialog(false)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Notifications
                        notifications={notifications}
                        removeFromNotifications={removeFromNotifications}
                        setNotifications={setNotifications}
                    />
                </Dialog>
            )}
        </AppBar>
    );
};

export default CustomAppBar;

const CompanySelect = () => {
    const { selectedCompany, setSelectedCompanyViaId, companies } = useCompanyContext();
    const [inputValue, setInputValue] = React.useState("");
    const handleChange = (event, newValue) => {
        setSelectedCompanyViaId(newValue.id);
    };

    const options = companies.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
        };
    });

    return (
        <>
            {selectedCompany && companies && (
                <Autocomplete
                    disablePortal
                    disableClearable
                    getOptionLabel={(option) => option.name}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    renderOption={(props, option) => (
                        <li
                            {...props}
                            key={option.id}
                            style={{
                                borderLeftStyle: "solid",
                                borderLeftWidth: 4,
                                borderLeftColor: option.active ? "green" : "red",
                                paddingLeft: "5px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                marginTop: "4px",
                                marginBottom: "4px",
                                marginLeft: "30px",
                            }}
                        >
                            {option.name}
                        </li>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    groupBy={(option) => option.firstLetter}
                    noOptionsText={"Kein Unternehmen gefunden"}
                    sx={{ m: 1, minWidth: 300 }}
                    renderInput={(params) => <TextField {...params} label="Unternehmen wechseln (Zum Suchen tippen)" />}
                    value={selectedCompany}
                    onChange={handleChange}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                />
            )}
            {/*selectedCompany && companies && (
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="company-select">Unternehmen wechseln</InputLabel>
                    <Select labelId="company-select" value={selectedCompany.id} onChange={handleChange}>
                        {companies &&
                            companies.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )*/}
        </>
    );
};
