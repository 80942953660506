import { Box, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
const IBANRegEx =
    /^AL\\d{10}[0-9A-Z]{16}$|^AD\\d{10}[0-9A-Z]{12}$|^AT\\d{18}$|^BH\\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\\d{14}$|^BA\\d{18}$|^BG\\d{2}[A-Z]{4}\\d{6}[0-9A-Z]{8}$|^HR\\d{19}$|^CY\\d{10}[0-9A-Z]{16}$|^CZ\\d{22}$|^DK\\d{16}$|^FO\\d{16}$|^GL\\d{16}$|^DO\\d{2}[0-9A-Z]{4}\\d{20}$|^EE\\d{18}$|^FI\\d{16}$|^FR\\d{12}[0-9A-Z]{11}\\d{2}$|^GE\\d{2}[A-Z]{2}\\d{16}$|^DE\\d{20}$|^GI\\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\\d{9}[0-9A-Z]{16}$|^HU\\d{26}$|^IS\\d{24}$|^IE\\d{2}[A-Z]{4}\\d{14}$|^IL\\d{21}$|^IT\\d{2}[A-Z]\\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\\d{5}[0-9A-Z]{13}$|^KW\\d{2}[A-Z]{4}22!$|^LV\\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\\d{6}[0-9A-Z]{20}$|^LI\\d{7}[0-9A-Z]{12}$|^LT\\d{18}$|^LU\\d{5}[0-9A-Z]{13}$|^MK\\d{5}[0-9A-Z]{10}\\d{2}$|^MT\\d{2}[A-Z]{4}\\d{5}[0-9A-Z]{18}$|^MR13\\d{23}$|^MU\\d{2}[A-Z]{4}\\d{19}[A-Z]{3}$|^MC\\d{12}[0-9A-Z]{11}\\d{2}$|^ME\\d{20}$|^NL\\d{2}[A-Z]{4}\\d{10}$|^NO\\d{13}$|^PL\\d{10}[0-9A-Z]{,16}n$|^PT\\d{23}$|^RO\\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\\d{2}[A-Z]\\d{10}[0-9A-Z]{12}$|^SA\\d{4}[0-9A-Z]{18}$|^RS\\d{20}$|^SK\\d{22}$|^SI\\d{17}$|^ES\\d{22}$|^SE\\d{22}$|^CH\\d{7}[0-9A-Z]{12}$|^TN59\\d{20}$|^TR\\d{7}[0-9A-Z]{17}$|^AE\\d{21}$|^GB\\d{2}[A-Z]{4}\\d{14}$/;
const BICRegEx = /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/;
function PaymentData({ handleBack, handleNext, formState, setFormState }) {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        handleNext();
        setFormState((prev) => ({ ...prev, billingInfos: data }));
    };

    return (
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Controller
                        defaultValue={formState?.billingInfos?.financeInstitute}
                        name="financeInstitute"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.financeInstitute} label={"Bankname *"} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        defaultValue={formState?.billingInfos?.accountHolder}
                        name="accountHolder"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.accountHolder} label={"Kontoinhaber *"} />}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Controller
                        defaultValue={formState?.billingInfos?.iban}
                        name="iban"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.iban} label={"IBAN *"} />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        defaultValue={formState?.billingInfos?.bic}
                        name="bic"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField {...field} error={!!errors?.bic} label={"BIC *"} />}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", my: 2 }}>
                    <Button color={"inherit"} onClick={handleBack} sx={{ mr: 1 }}>
                        Zurück
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type={"submit"}>Weiter</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PaymentData;
