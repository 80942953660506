import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
function CheckBoxLabel({ name, api, description }) {
    return (
        <Box>
            <Typography variant={"body1"}>
                {name} <i>({api ? "Automatische Überwachung" : "Manuelle Überwachung"})</i>
            </Typography>
            <Typography variant={"body2"}>{description}</Typography>
        </Box>
    );
}

function Portals({ handleBack, handleNext, formState, setFormState, portals }) {
    useEffect(() => {
        if (portals?.length > 0) {
            for (const portal of portals) {
                setFormState((prev) => ({ ...prev, apis: { ...prev.apis, [portal.id]: { active: !!portal.defaultChecked, link: "" } } }));
            }
        }
    }, [portals]);
    const handleCheck = (e) => {
        console.log(e.target.name);
        setFormState((prev) => ({ ...prev, apis: { ...prev.apis, [e.target.name]: { active: e.target.checked, link: "" } } }));
    };

    return (
        <Box component={"form"} onSubmit={handleNext}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormGroup>
                        {portals.length > 0 &&
                            portals.map((portal) => (
                                <FormControlLabel
                                    sx={{ py: 1 }}
                                    key={portal.id}
                                    control={
                                        <Checkbox
                                            defaultChecked={portal.defaultChecked}
                                            name={portal.id}
                                            onChange={handleCheck}
                                            checked={formState?.apis?.[portal.id]?.active}
                                        />
                                    }
                                    label={<CheckBoxLabel name={portal.name} api={portal.api} description={portal.description} />}
                                />
                            ))}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", my: 2 }}>
                    <Button color={"inherit"} onClick={handleBack} sx={{ mr: 1 }}>
                        Zurück
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type={"submit"}>Weiter</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Portals;
