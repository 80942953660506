import { CreateButton, Datagrid, DateField, FunctionField, List, TextField, TextInput, TopToolbar } from "react-admin";
import { Box } from "@mui/material";
import { useState } from "react";

const ListActions = () => (
    <TopToolbar>
        <CreateButton label={"Neuer Benutzer"} size={"large"} alignIcon={"right"} />
    </TopToolbar>
);

const ListFilters = [<TextInput label="E-Mail" source="email" alwaysOn />];

function UserList() {
    return (
        <List perPage={50} actions={<ListActions />}>
            <Datagrid
                bulkActionButtons={false}
                rowClick="edit"
                sx={{
                    "& .RaDatagrid-rowOdd": {
                        backgroundColor: "rgb(245,245,245)",
                    },
                    "& .RaDatagrid-rowCell": {
                        py: 1.5,
                    },
                }}
            >
                <TextField source={"email"} label={"E-Mail"} />
                <TextField source={"firstName"} label={"Vorname"} />
                <TextField source={"lastName"} label={"Nachname"} />
                <FunctionField source={"role"} label={"Rolle"} render={(record) => <RoleField record={record} />} />
                <FunctionField label={"Anzahl zugeordnete Kunden"} render={(record) => <AssignedCompaniesCount record={record} />} />
                {/*  <FunctionField label={"Initiales Passwort"} render={(record) => <PasswordField record={record} />} />*/}
                <DateField source={"createDate"} label={"Registriert am"} options={{ year: "numeric", month: "2-digit", day: "2-digit" }} />
            </Datagrid>
        </List>
    );
}

function AssignedCompaniesCount({ record }) {
    return record["companies"]?.length;
}
function RoleField({ record }) {
    return record["role"] === "admin" ? <strong>Admin</strong> : "Benutzer";
}

function PasswordField({ record }) {
    //initialPass
    const [show, setShow] = useState(false);
    return (
        <Box onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            {" "}
            {show ? <>{record["initialPass"]}</> : <>&#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;</>}
        </Box>
    );
}

export default UserList;
