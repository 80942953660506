import { Box, Typography } from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";

function StatusColorDescription() {
    return (
        <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography>
                <SquareIcon fontSize={"small"} sx={{ verticalAlign: "middle", ml: 2, mb: "2px", color: "#a9a9a9" }} />
                Gemeldet
            </Typography>
            <Typography>
                <SquareIcon fontSize={"small"} sx={{ verticalAlign: "middle", ml: 2, mb: "2px", color: "#fbbd04" }} />
                In Bearbeitung
            </Typography>
            <Typography>
                <SquareIcon fontSize={"small"} sx={{ verticalAlign: "middle", ml: 2, mb: "2px", color: "#34a853" }} />
                Erfolgreich
            </Typography>
            <Typography>
                <SquareIcon fontSize={"small"} sx={{ verticalAlign: "middle", ml: 2, mb: "2px", color: "#ea4336" }} />
                Löschung verweigert
            </Typography>
        </Box>
    );
}

export default StatusColorDescription;
