import { Alert, AlertTitle, Box, Button, Divider, Typography } from "@mui/material";
import RegisterStepper from "./RegisterStepper";

function Register({ setRegister, referralCodeActive }) {
    return (
        <Box
            sx={{
                bgcolor: "rgba(255,255,255,0.95)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                borderRadius: 5,
                p: 5,
            }}
        >
            <Typography variant={"h6"} fontWeight={"bold"} alignSelf={"flex-start"} width={"100%"} pb={1} sx={{ color: "#5e5e5e" }}>
                Registrierung bei StarProtection
            </Typography>
            {referralCodeActive && (
                <Alert sx={{ mt: 2 }}>
                    <AlertTitle>Sie verwenden einen Empfehlungslink zur Registrierung.</AlertTitle>
                </Alert>
            )}
            <RegisterStepper setRegister={setRegister} referralCodeActive={referralCodeActive} />
            <Divider sx={{ my: 2, width: "100%" }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Haben Sie bereits einen Zugang?</Typography>
                <Button onClick={() => setRegister(false)}>Login!</Button>
            </Box>
        </Box>
    );
}

export default Register;
