import { useCompanyContext } from "../../context/CompanyContext";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import * as React from "react";
import { useEffect, useState } from "react";
import { firestore } from "../../App";
import { Analytics, getPieChartColor } from "../Dashboard";
import GoogleReviewCard from "../CustomerReviews/GoogleReviewCard";

function NoCompetitorsAlert() {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Alert severity="warning">
                <AlertTitle> Es sind keine Mitbewerber hinterlegt. </AlertTitle>
                Sie können bis zu 3 Mitbewerber unter "Kundendaten -> Mitbewerber" hinzufügen, die Sie mitverfolgen möchten.
            </Alert>
        </Box>
    );
}
function Competitors() {
    const { selectedCompany } = useCompanyContext();
    if (!selectedCompany.competitors || selectedCompany.competitors.length === 0) return <NoCompetitorsAlert />;
    selectedCompany.competitors.forEach((competitor) => {
        competitor.id = uuidv4();
    });
    return <CompetitorsReviews competitors={selectedCompany.competitors} />;
}

function CompetitorsReviews({ competitors }) {
    const [selected, setSelected] = useState(competitors[0]);
    const [distribution, setDistribution] = useState([]);
    const [last, setLast] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lastReviews, setLastReviews] = useState([]);
    useEffect(() => {
        if (selected && selected["Google"].id) fetchData();
    }, [selected]);
    const collectionReference = firestore.collection("reviews").doc(selected["Google"].id).collection("google");
    async function fetchData() {
        setLastReviews([]);
        setDistribution([]);
        setIsLoading(true);
        setLast(null);
        const collections = await collectionReference.limit(12).orderBy("datetime", "desc").get();
        const reviewsCache = [];
        if (collections.empty) {
            // todo handle no reviews
        } else {
            console.log("get reviews collections", collections.size);
            for (const coll of collections.docs) {
                const collReviews = coll.data();
                reviewsCache.push(collReviews);
            }
        }
        setIsLoading(false);
        setLastReviews(reviewsCache);
        setLast(collections.docs[collections.size - 1]);

        const distributionCache = [];
        if (selected["Google"].distribution) {
            Object.keys(selected["Google"].distribution).map((key) => {
                distributionCache.push({
                    title: key.toString(),
                    value: selected["Google"].distribution[key],
                    color: getPieChartColor(key.toString()),
                });
            });
            setDistribution(distributionCache);
        } else {
            setDistribution([]);
        }
    }

    const handleLoadMoreReviews = () => {
        collectionReference
            .orderBy("datetime", "desc")
            .startAfter(last)
            .limit(9)
            .get()
            .then((collections) => {
                const reviewsCache = [];
                console.log("get reviews collections", collections.size);
                for (const coll of collections.docs) {
                    const collReviews = coll.data();
                    reviewsCache.push(collReviews);
                }
                setLast(collections.docs[collections.size - 1]);
                setLastReviews((prevState) => prevState.concat(reviewsCache));
            });
    };

    return (
        <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} sx={{ display: "flex", alignItem: "center", justifyContent: "space-between" }}>
                <img src={"/google_logo.svg"} height={50} />
                <CompetitorsSelection competitors={competitors} selected={selected} setSelected={setSelected} />
            </Grid>
            {distribution.length > 0 && (
                <>
                    <Grid item xs={12} sx={{ display: "flex", alignItems: "end" }}>
                        <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Analytics selectedCompany={selected} distribution={distribution} competitor />
                </>
            )}
            <Grid item xs={12} sx={{ display: "flex", alignItems: "end" }}>
                <Typography variant={"h6"} sx={{ ml: 1, mb: 0.5 }}>
                    Neueste Bewertungen
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "end" }}>
                <Divider sx={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12}>
                {isLoading && (
                    <Box width={"100%"} textAlign={"center"}>
                        Daten werden geladen <CircularProgress />
                    </Box>
                )}
                <Grid container spacing={2}>
                    {lastReviews.length > 0 && lastReviews.map((review) => <GoogleReviewCard review={review} key={review.id} company={null} />)}
                </Grid>
                <Box py={2} textAlign={"center"}>
                    <Button onClick={() => handleLoadMoreReviews()}>Mehr Bewertungen laden</Button>
                </Box>
            </Grid>
        </Grid>
    );
}

function CompetitorsSelection({ competitors, selected, setSelected }) {
    const handleChange = (e) => {
        setSelected(competitors.find((competitor) => competitor.id === e.target.value));
    };
    return (
        <FormControl variant="standard" sx={{ m: 1, width: "25% !important" }}>
            <InputLabel id="company-select">Mitbewerber wechseln</InputLabel>
            <Select labelId="company-select" value={selected.id} onChange={handleChange}>
                {competitors &&
                    competitors.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                            {company.companyName}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
}
export default Competitors;
